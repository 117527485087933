<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100% - 220px); display: flex;"
  >
    <div id="panelObrigado"></div>
    <el-dialog
      v-model="dialogVisible"
      title=""
      :fullscreen="true"
      @open="fecharDialog"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <template #footer>
        <el-image
          :src="successImage"
          id="success-image"
          style="min-width: 100px; max-width: 500px; width: 100%; heigh: 100%"
        ></el-image>
        <span class="dialog-footer">
          <h1>Obrigado!</h1>
        </span>
      </template>
    </el-dialog>

    
    <el-row style="flex-direction: column; align-items: center;">
      <el-col :span="24" style="margin: 0px; padding: 0px"><h1>Toque na Foto do Candidato</h1></el-col>
    </el-row>
    <el-row justify="space-around">
      <el-col        
        v-for="(item, index) in candidatos"
        :key="item.id"
        :xs="8"
        :sm="8"
        :md="tamanhoColunaMD"        
      >
        <el-card
          :body-style="{ padding: '0px' }"
          shadow="hover"
          @click="selecionarCandidato(item, index)"
          :class="{ 'card-selected': index === indexSelected }"
          style="height: 100%"
        >
          <img :src="getImage(item)" class="image" />
          <div style="padding: 14px">
            <span>{{ item.aluno.nome }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin: auto;"> 
      <el-col :span="24" style="padding: 0px;">
        <div class="card-votacao">
          <div class="card-votacao-content">
            <b>Candidato Selecionado: </b>
            <p class="nome-candidato">{{ candidatoSelecionado.aluno.nome }}</p>
          </div>
        </div>
      </el-col>
      <el-col  :span="24" style="margin: 0px; padding: 0px; flex-direction: row; align-items: center; justify-content: center; display: flex;">
        <el-button type="success" :size="buttonSize" @click="votar()">VOTAR</el-button>
        <el-button type="danger" :size="buttonSize" @click="openModalAnulacao()">ANULAR</el-button>        
      </el-col>      
    </el-row>    
    <el-dialog v-model="modalAnulacaoVisible" title="Anular VOTO">
      <span>Deseja realmente <b>ANULAR</b> o voto?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="large" @click="modalAnulacaoVisible = false">Cancelar</el-button>
          <el-button size="large" type="primary" @click="anular()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import VotacaoModel from "@/models/VotacaoModel";
import ParametroModel from "@/models/ParametroModel";
import { ElLoading } from "element-plus";
import { getAccessTokenAsParam } from "@/utils/auth";

export default {
  name: "Votacao",
  mixins: [ToastMixin],
  components: [ElLoading],

  data() {
    return {
      parametro: {
        id: 1,
        vot_tempo_msg_obrigado: 3,
        vot_tempo_msg_aguarde: 3,
      },      
      tamanhoColunaMD: 8,
      buttonSize: 'large',
      audioSelecao: null,
      audioConfirmacao: null,
      modalAnulacaoVisible: false,
      successImage: require("@/assets/images/success.svg"),
      dialogVisible: false,
      indexSelected: null,
      fullscreenLoading: false,
      loading: null,
      candidatoSelecionado: {
        id: null,
        aluno: {
          nome: "(nenhum)",
        },
      },
      candidatos: [],
      candidato_image: require("@/assets/images/candidato.png"),
    };
  },
  created() {
    /*document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false }); */

    this.audioSelecao = new Audio(
      require("@/assets/sounds/efeito-selecao.mp3")
    );
    this.audioConfirmacao = new Audio(
      require("@/assets/sounds/efeito-confirmacao.mp3")
    );

    try {
      this.candidatos = JSON.parse(this.$route.params.candidatos);      
    } catch (e) {
      this.candidatos = [];
    }

    if (this.candidatos === undefined || this.candidatos.length <= 0) {
      this.$router.push({ name: "votacao-list" });
    }else{
      let nCandidato = this.candidatos.length;
      if (nCandidato <= 3)
        this.tamanhoColunaMD = 6
      else if (nCandidato <= 4)
        this.tamanhoColunaMD = 6
      else if (nCandidato <= 11){
        this.tamanhoColunaMD = 4;
        this.buttonSize = 'default';
      }        
      else{
        this.buttonSize = 'small';
        this.tamanhoColunaMD = 2;
      }         
    }

    ParametroModel.find(1).then((response) =>{
      this.parametro = response;
    });
  },
  unmounted() {
    //
  },
  methods: {
    previnirZoom() {},
    getImage(item) {
      if (item.aluno.image_path != null) {
        let token = getAccessTokenAsParam();
        return item.aluno.image_path + token;
      } else return this.candidato_image;
    },
    tocarEfeitoSelecao() {
      //alert("why would this matter?");
      //if (this.audioTeste.paused) {
      this.audioSelecao.play();
      //} else {
      //  this.audioTeste.currentTime = 0;
      //}
      /*var audioSelecao = new Audio(
        require("@/assets/sounds/efeito-selecao.mp3")
      );
      audioSelecao.addEventListener("canplaythrough", function () {
        alert("why would this matter?");
        audioSelecao.play();
      });*/
    },
    tocarEfeitoConfirmacao() {
      this.audioConfirmacao.play();
      /*var audioSelecao = new Audio(
        require("@/assets/sounds/efeito-confirmacao.mp3")
      );
      audioSelecao.addEventListener("canplaythrough", function () {
        audioSelecao.play();
      });*/
    },
    selecionarCandidato(selected, indexSelected) {
      this.tocarEfeitoSelecao();
      this.candidatoSelecionado = { ...selected };
      this.candidatoSelecionado.aluno = { ...selected.aluno };
      this.indexSelected = indexSelected;
    },
    fecharDialog() {
      setTimeout(() => {
        //loadingInstance.close();
        this.dialogVisible = false;        
      }, this.parametro.vot_tempo_msg_obrigado*1000);
    },
    openFullscreen2() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        //background: 'rgba(0, 0, 0, 0.1)',
        lock: true,
      });

      setTimeout(() => {
        loadingInstance.close();
        this.dialogVisible = true;
      }, this.parametro.vot_tempo_msg_aguarde*1000);
    },
    openModalAnulacao() {
      this.modalAnulacaoVisible = true;
    },
    async anular() {
      
      let voto = JSON.parse(
        '{"candidato_id": null,' +
          '"escola_eleicao_id": ' +
          this.candidatos[0].escola_eleicao.id +
          "}"
      );

      await this.gravarVoto(voto);
      this.modalAnulacaoVisible = false;
    },
    async gravarVoto(voto) {
      let Votacao = [];

      Votacao = new VotacaoModel(voto);
      let loading;

      try {
        loading = ElLoading.service({
          text: "Aguarde",
          lock: true,
        });

        await Votacao.save()
          .then(() => {            
            this.indexSelected = null;

            setTimeout(() => {
              loading.close();
              this.dialogVisible = true;
              this.tocarEfeitoConfirmacao();
              this.candidatoSelecionado.aluno.nome = "(nenhum)";
              this.candidatoSelecionado.id = null;
            }, this.parametro.vot_tempo_msg_aguarde*1000);
          })
          .catch((error) => {
            loading.close();
            let msgError ="";

            if (error.response) {
              msgError = error.response.data.message 
            }else
              msgError = error;

            this.showToast(
              "error",
              "",
              "Erro ao votar. " + msgError
            );
          });
      } catch (err) {
        loading.close();
        this.showToast("error", "", "Erro ao votar. " + err);
      }
    },
    async votar() {     
      if (
        this.candidatoSelecionado === undefined ||
        this.candidatoSelecionado.id === null
      ) {
        this.showToast("warning", "", "Selecione o candidato", 'el-message-warning-dark');
      } else {
        let voto = JSON.parse(
          '{"candidato_id": ' +
            this.candidatoSelecionado.id +
            "," +
            '"escola_eleicao_id": ' +
            this.candidatos[0].escola_eleicao.id +
            "}"
        );        
        await this.gravarVoto(voto);
      }
    },
  },
};
</script>

<style scoped>
.card-votacao {
  /*display: flex;
  flex-direction: row;
  align-items: center;*/
  width: 100%;
  height: 100%;
  margin-left: 5px;
}

.card-votacao-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 5px;
}


.nome-candidato {
  font-size: x-large;
}


.el-col {
  /*margin-top: 10px;*/
  padding: 5px;
}

.el-button--large {
  height: 60px;
  width: 120px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0px !important;
}

.el-button--default {
  height: 40px;
  width: 100px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0px !important;
}

.el-card:hover {
  cursor: pointer;
}

.el-card{
  box-shadow: 0px -5px 10px 0px rgb(0 0 0 / 50%);
}

.card-selected {
  border-color: #fd6161;
  /*border-color: var(--color-background-nav);*/
  border-width: 3px;
  font-weight: bold;  
}

.time {
  font-size: 12px;
  color: #999;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  /*max-height: 230px;*/
  /*max-width: 300px;*/
  width: 100%;
  display: block;
}


</style>