<template>
  <el-container class="header-completo">
    <el-header style="height: 160px; padding: 0">
      <el-container
        class="menu-logo"
        style="
          background-color: white;
          max-width: 1200px;
          margin: 0 auto;
          height: 110px;
        "
      >
        <a
          href="/"
          style="width: 100%; max-width: 1200px; justify-content: left"
        >
          <el-row style="margin: 5px">
            <el-col :span="6" style="margin: 0; max-width: 95px; max-height: 95px">
              <img
                style="width: 95px; height: 95px;"
                :src="logoImage"
              />
            </el-col>

            <el-col :span="17" style="margin-top: 10px">
              <h1>Câmara Municipal de Ibitinga</h1>
              <h2>Sistema de Apoio ao Parlamento Jovem</h2>
            </el-col>
          </el-row>
        </a>
      </el-container>

      <el-container
        class="no-print"
        style="
          background-color: var(--color-background-nav);
          width: 100%;
          height: 49px;
        "
      >
        <el-container
          class="menu-nav"
          style="
            background-color: green;
            max-width: 1200px;
            height: 50px;
            margin: 0 auto;
          "
        >
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            :unique-opened="true"
            :router="true"
            background-color="var(--color-background-nav)"
            text-color="#fff"
            active-text-color="#ffd04b"
            style="width: 100%"
            menu-trigger="click"
            :ellipsis="true"
          >
            <el-menu-item index="1" route="/" v-if="menuAdmin"
              >Home</el-menu-item
            >
            <el-sub-menu index="2" v-if="menuAdmin">
              <template #title>Cadastros</template>
              <el-menu-item index="2-1" route="/aluno">Aluno</el-menu-item>
              <el-menu-item index="2-2" route="/escola">Escola</el-menu-item>
              <el-menu-item index="2-3" route="/usuario">Usuário</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3" v-if="menuAdmin">
              <template #title>Eleição</template>
              <el-menu-item index="3-1" route="/eleicao">Eleição</el-menu-item>
              <el-menu-item index="3-2" route="/candidato">Candidatos</el-menu-item>              
              <el-menu-item index="3-3" route="/zeresima">Zerésima</el-menu-item>
              <el-menu-item index="3-4" route="/boletim-urna">Boletim de Urna</el-menu-item>
              <el-menu-item index="3-5" route="/apuracao">Resultados</el-menu-item>              
            </el-sub-menu>
            <el-menu-item index="4" route="/votacao" v-if="menuAdmin">Votação</el-menu-item>


            <el-sub-menu index="5" v-if="menuAdmin">
              <template #title>Configuração</template>
              <el-menu-item index="5-1" route="/parametro">Parâmetros</el-menu-item>
              <el-menu-item index="5-2" route="/escola-eleicao">Eleição na Escola</el-menu-item>                            
            </el-sub-menu>            

            <el-sub-menu index="6" class="dock-right">
              <template #title>
                
                <span><el-icon><Avatar /></el-icon>{{ loggedUser }}</span>
              </template>
              <el-menu-item index="6-1" route="#" @click="logout()">
                <el-icon><Right /></el-icon>
                <span>Sair</span>
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-container>
      </el-container>
    </el-header>
  </el-container>
</template>

<script>
import { ElLoading } from "element-plus";
import { signOut, hasRole, getLoggedUser } from "../utils/auth";
import ToastMixin from "@/mixins/toastMixin";
import { Right, Avatar } from "@element-plus/icons-vue";

export default {
  mixins: [ToastMixin],
  components: { Right, Avatar},  
  data() {
    return {
      loggedUser: null,
      menuAdmin: false,
      activeIndex2: 1,
      logoImage: require("@/assets/images/logo_camara_mirim_peq.png"),
    };
  },
  created() {
    this.menuAdmin = hasRole(1);
    this.loggedUser = getLoggedUser();
  },
  methods: {
    getLoggedUser,
    hasRole,
    logout() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });
      
      signOut()
        .then()
        .catch((error) => {
          this.showToast("error", "", error);
        })
        .finally(() => {
          this.$router.push("/login");          
          loadingInstance.close();
          //console.log(this);          
        });      
    },
    select: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
.header-completo {
  height: 160px;
}

.dock-right {
  margin-left: auto;
}

@media print {
  .header-completo {
    height: 100px;
  }
}

#logo {
  width: 130px;
}

h2 {
  font-size: 17px;
  color: var(--color-text-dark);
}

h1 {
  font-size: 28px;
}

@media (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 13px;
  }
}
</style>

