<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <el-row>
      <el-col :span="24"><h1>Lista de Escolas</h1></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <el-button size="small" type="primary" @click="criarEscola()"
            >Criar Escola</el-button
          >

          <template v-if="isSchoolsEmpty">
            <div class="empty-data">
              <el-image :src="emptyImage" class="empty-data-image"></el-image>
            </div>
            ;;
          </template>
          <el-table
            v-loading="loading"
            class="el-table"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :data="
              schools.filter(
                (data) =>
                  !search ||
                  data.nome.toLowerCase().includes(search.toLowerCase())
              )
            "
            v-else
          >
            <el-table-column label="Cód." prop="id" width="100" />
            <el-table-column label="Apelido" prop="apelido" width="200" />
            <el-table-column label="Nome" prop="nome" width="250" />
            <el-table-column
              label="Fone"
              prop="fone1"
              :formatter="formatterTelefone"
              width="150"
            />
            <el-table-column
              label="Ativo"
              prop="ativo"
              :formatter="formatterAtivo"
              width="100"
            />
            <el-table-column align="right">
              <template #header>
                <el-input
                  v-model="search"
                  size="small"
                  placeholder="Digite o nome da escola"
                />
              </template>
              <template #default="scope">
                <el-button size="small" @click="editar(scope.row.id)"
                  >Editar</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="openModalExclusao(scope.row.id)"
                  >Excluir</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span
        >Deseja realmente excluir esta escola? {{ schoolSelected.nome }}</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="remover()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import EscolaModel from "@/models/EscolaModel";
import StatusCad from "@/utils/statusCad";
import formatStrings from "@/utils/formatStrings";

export default {
  name: "EscolaList",
  mixins: [ToastMixin],
  data() {
    return {
      search: "",
      schools: [],
      loading: true,
      schoolSelected: [],
      modalExclusaoVisible: false,
      emptyImage: require("@/assets/images/empty-school.svg"),
      status: StatusCad,
      optionsList: [
        { value: null, text: "Selecione algum status" },
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    this.loading = true;
    let data = await EscolaModel.get();
    this.schools = data.data;
    this.loading = false;
    //this.schools = (localStorage.getItem("schools")) ? JSON.parse(localStorage.getItem("schools")) : [];
  },
  methods: {
    criarEscola() {
      this.$router.push({ name: "escola-create" });
    },
    formatterTelefone(row) {
      return formatStrings.formatarTelefoneFixo(row.fone1);
    },
    formatterAtivo(row) {
      return row.ativo ? "Sim" : "Não";
      //return this.optionsList[row.ativo+1].text;
    },
    editar(escolaId) {
      this.$router.push({ name: "escola-edit", params: { id: escolaId } });
    },
    async openModalExclusao(escolaId) {
      //this.schoolSelected = school;
      this.schoolSelected = await EscolaModel.find(escolaId);
      this.modalExclusaoVisible = true;
    },
    async remover() {
      //this.schools.splice(this.schoolSelected,1);
      //localStorage.setItem("schools", JSON.stringify(this.schools));
      await this.schoolSelected.delete();
      let data = await EscolaModel.get();
      this.schools = data.data;
      this.showToast("success", "", "Escola removida com sucesso");
      this.modalExclusaoVisible = false;
    },
    async updateStatus(escolaId, status) {
      let school = await EscolaModel.find(escolaId);
      school.status = status;
      await school.save();

      this.schools = await EscolaModel.params({
        status: [this.status.ATIVO],
      }).get();

      this.showToast("success", "", "Escola inativada com sucesso");
    },
    tableRowClassName({ row }) {
      if (row.status === 0) {
        return "success-row";
      } else if (row.status === 1) {
        return "warning-row";
      }
      return "";
    },
  },
  computed: {
    isSchoolsEmpty() {
      return this.schools.length === 0 && this.loading == false;
    },
  },
};
</script>

<style>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
