<template>
    <main>        
        <section class="container">
            <h1>Contato</h1>
            <section class="contacts">    
                <div class="contact" v-for="(contato, index) in contatos" :key="index">  
                    <div class="contact-title">{{contato.title}}</div>                    
                    <a :href="contato.link" target="_blank">{{contato.label}}</a>                    
                </div>              
            </section>             
        </section>
    </main>
</template>


<script>
import api from '@/services/api.js';

export default {
    name: 'Contatos',
    data(){
        return{
            contatos: []
        }
    },
    mounted(){
        api.get('/contacts.json').then(response =>{            
            this.contatos = response.data;            
        })
    }    
}




</script>

<style scoped>
    main{
        align-items: center;
    }

    .contacs{        
      align-items: center;
      width: 100%;
    }

    .contact{
        margin-top: 10px;
        color: var(--color-text-dark);
        align-items: center;        
    }

    .contact-title{
        font-weight: bold;
    }

    .contact a {
        
        color: var(--color-text-dark);        
    }
  


</style>
