<template>  
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">     
      <div class="grid-content bg-purple-light"></div>
        <div class="grid-content bg-purple-dark">
          <div class="app-container">            
            <el-form ref="formRef" :model="formModel" :rules="rules" label-width="120px" style="max-width: 460px">              
              <el-row>
                  <el-col :xs="24" :offset="6"> 
                    <h1>Cadastro de Eleição</h1>
                  </el-col>
                  <el-col :xs="24">              
                    <el-form-item label="Ano" prop="ano">
                      <el-input v-model="formModel.ano"></el-input>
                    </el-form-item>                
                  </el-col>         
                  <el-col :xs="24">              
                    <el-form-item label="Descrição" prop="descricao">
                      <el-input v-model="formModel.descricao"></el-input>
                    </el-form-item>                
                  </el-col>         
                <el-col :xs="24">              
                  <el-form-item label="Data Posse" prop="dtposse">                    
                    <el-date-picker v-model="formModel.dtposse" type="date" format="DD/MM/YYYY" value-format="YYYY-MM-DD" placeholder="Selecione a data da posse"/>        
                  </el-form-item>                
                </el-col>                                                       
                  <el-col :xs="24">
                    <el-form-item label="Ativo" prop="ativo">
                      <el-select v-model="formModel.ativo" placeholder="Select">
                        <el-option
                          v-for="item in optionsList"
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>         
                  </el-col>
                  <el-col :xs="24">
                    <el-form-item>
                      <el-button type="primary"  @click="onSubmit('formRef')">Salvar</el-button>
                      <el-button  @click="testePushRouter">Cancelar</el-button>
                    </el-form-item>
                  </el-col>
              </el-row>
            </el-form>
          </div>
        </div>    
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import EleicaoModel from "@/models/EleicaoModel";
import StatusCad from "@/utils/statusCad";

export default {
  name: "Eleicao",
  mixins: [ToastMixin],
  data() {
    return {
      formModel: {
        nome: "",
        dtposse: "",
        ativo: StatusCad.ATIVO,
      },
      rules: {
        ano: [
          {
            required: true,
            message: 'Preencha o Ano da Eleicao',
            trigger: "blur",
          },
        ],
        descricao: [
          {
            required: true,
            message: 'Preencha a Descrição',
            trigger: "blur",
          },
        ],        
      },
      methodSave: "new",
      optionsList: [
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.methodSave = "update";
      this.formModel = await EleicaoModel.find(this.$route.params.id);
    }
  },
  methods: {
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {          

          let eleicao = [];
          if (this.methodSave === "update") {
            eleicao = this.formModel;
          } else {
            eleicao = new EleicaoModel(this.formModel);
          } 

          await eleicao
            .save()
            .then(() => {
              this.showToast("success", "", "Eleicao salva com sucesso");
              this.$router.push({ name: "eleicao-list" });
            })
            .catch((error) => {
              this.showToast("error", "", "Erro ao salvar: " + error.response.data.message);
              console.log("deu erro " + error);
            });          
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    testePushRouter() {      
      this.$router.push({ name: "eleicao-list" });
    },
  },
};
</script>

<style>
</style>