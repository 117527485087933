<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <el-form ref="formRef" :model="filtro" :rules="rules">
      <el-row>
        <el-col :span="24"><h1>Boletim de Urna</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Escola" prop="escola_id">
            <el-select
              v-model="filtro.escola_id"
              clearable
              placeholder="Selecione a Escola"
            >
              <el-option
                v-for="item in escolaToFilter"
                :key="item.id"
                :label="item.nome"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item>
            <el-button type="primary" @click="onSubmit('formRef')"
              >Gerar</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24">
        <el-tabs
          type="border-card"
          class="demo-tabs"
          v-if="!isEscolaEleicaoEmpty"
        >
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <span>Resultados</span>
              </span>
            </template>

            <el-row>
              <el-col :span="24">                  
                <el-dropdown style="float: right" @command="exportarPDF">
                  <el-button>
                    Exportar<el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="opcao1"
                        ><el-icon class="el-icon--right"><Document /></el-icon
                        >Opção 1</el-dropdown-item
                      >
                      <el-dropdown-item command="opcao2"
                        ><el-icon class="el-icon--right"><Document /></el-icon
                        >Opção 2</el-dropdown-item
                      >                                                      
                    </el-dropdown-menu>
                  </template>
                </el-dropdown> 


                <!--<el-button style="float: right" @click="exportarPDF">
                  <el-icon class="el-icon--left"><Document /></el-icon>
                  PDF
                </el-button>-->     
                <h4>Data do Boletim: {{ dateTimeBoletim }}</h4>                             
              </el-col>                
            </el-row>
            <el-row :gutter="20">
              <el-col
                v-for="item in escolaEleicao"
                :key="item.id"
                :span="24"
                style="margin-right: 0px"
              >
                <el-card
                  :body-style="{ padding: '0px' }"
                  shadow="hover"
                  style="margin-bottom: 20px; margin-top: 10px"
                >
                  <div style="padding: 14px">
                    <el-row>
                      <el-col :span="24">
                        <b>
                          {{ item.escola.nome }} - Ensino {{ item.ensino }}</b
                        >
                      </el-col>
                      <el-col :span="24" style="margin-top: 10px">
                        <b>Votos Total: </b> {{ item.votos_total }}
                      </el-col>
                      <el-col :span="24" style="margin-top: 10px">
                        <b>Votos Nulo: </b> {{ item.votos_nulo }}
                      </el-col>
                    </el-row>
                    <div class="demo-progress">
                      <div
                        class="candidato"
                        v-for="item2 in item.candidato"
                        :key="item2.id"
                        style="margin-top: 10px"
                      >
                        <div class="candidato-avatar">
                          <el-avatar
                            shape="square"
                            :size="50"
                            :src="getImage(item2)"
                          />
                        </div>
                        <div class="candidato-conteudo">
                          <span
                            >{{ item2.aluno.nome }} - <b>Votos: </b
                            >{{ item2.votos }}
                          </span>
                          <el-progress
                            :text-inside="true"
                            :stroke-width="26"
                            :percentage="item2.porcentagem"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>            
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { Document, ArrowDown } from "@element-plus/icons-vue";
import { ElLoading } from "element-plus";
import api from "@/services/api.js";
import ToastMixin from "@/mixins/toastMixin";
import EscolaEleicaoModel from "@/models/EscolaEleicaoModel";
import EleicaoModel from "@/models/EleicaoModel";
import EscolaModel from "@/models/EscolaModel";
import moment from "moment";
import { getAccessTokenAsParam } from "@/utils/auth";

//import { writeFile } from 'fs/promises';

export default {
  name: "BoletimUrna",
  components: {    
    Document,
    ArrowDown
  },  
  mixins: [ToastMixin], 
  data() {
    return {
      dateTimeBoletim: "",
      filtro: {
        eleicao_id: null,
        escola_id: null,
      },
      rules: {
        escola_id: [
          {
            required: true,
            message: "Selecione a Escola",
            trigger: "blur",
          },
        ],
      },
      search: "",      
      escolaEleicao: [],
      eleicaoToFilter: [],
      escolaToFilter: [],
      candidato_image: require("@/assets/images/candidato.png"),
    };
  },
  async created() {
    await this.getEleicao();
    await this.getEscola();
  },
  methods: {
    async exportarPDF(opcao) {    
      if (opcao == "opcao1") {
        api({
          url: "escolaeleicao/boletimUrnaPDFExport",
          data: this.escolaEleicao,
          method: "POST",
          /*responseType: "text/html"*/
          responseType: "blob",
        }).then((response) => {
            /*let responseHtml = response.data;                  
            var myWindow = window.open("", "response", "resizable=yes");
            myWindow.document.write(responseHtml);*/

          window.open(URL.createObjectURL(response.data));
        });            
      } else if (opcao=='opcao2'){
        api({
          url: "escolaeleicao/boletimUrnaPDFExport",
          data: this.escolaEleicao,
          method: "POST",
          responseType: "blob",
        }).then((response) => {
          const responseHtml = response.data;
          let a = document.createElement("a");
          let blobURL = URL.createObjectURL(responseHtml);
          let escolaSelecionada = this.escolaToFilter.find(item => item.id === this.filtro.escola_id); 
          a.download = "BoletimUrna_"+escolaSelecionada.apelido+".pdf";
          a.href = blobURL;
          a.click();
          //window.open(URL.createObjectURL(response.data));
        });        
      }
    },    
    getImage(item) {
      if (item.aluno.thumbnail_path != null) {
        let token = getAccessTokenAsParam();
        return item.aluno.thumbnail_path + token;
      } else return this.candidato_image;
    },
    formatterDate(row) {
      return moment(String(row.dtnasc)).format("MM/DD/YYYY");
    },
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          EscolaEleicaoModel.custom("escolaeleicao/apuracao")
            .params({
              eleicao_id: this.filtro.eleicao_id,
              escola_id: this.filtro.escola_id,
            })
            .get()
            .then((response) => {
              this.escolaEleicao = response.data;
              this.dateTimeBoletim = response.meta.dateTime;

              if (this.escolaEleicao.length == 0){
                this.showToast("error", "", "Nenhum candidato encontrado");
              }              
            })
            .catch((error) => {
              console.log(error);
              this.showToast(
                "error",
                "",
                "Problema ao excluir registro: " + error
              );
            });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    async getEscola() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

      let response = await EscolaModel.params({
        ativo: 1,
      }).get();
      this.escolaToFilter = response.data;

      loadingInstance.close();
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  computed: {
    isEscolaEleicaoEmpty() {
      return this.escolaEleicao.length == 0;
    },
  },
};
</script>

<style>
.candidato-conteudo {
  display: block;
  width: 100%;
}

.candidato-avatar {
  margin-right: 3px;
}

.candidato {
  display: flex;
  flex-direction: row;
}
</style>
