import { ElMessage } from 'element-plus'

export default{
    methods:{
        showToast(variant, title, description, customClass){
            ElMessage({
                message: description,
                type: variant,                
                showClose: true,
                duration: 3000,
                'custom-class': customClass
            })
        }
    }
}