import { Model as BaseModel } from "vue-api-query";
import api from "@/services/api.js";

BaseModel.$http = api;

export default class Model extends BaseModel {
    resource(){
        return "";
    }

    baseURL() {
        //return "http://localhost:3000";
        //return "http://eleicaobackend.test/api";        
        //console.log("base local TITLE: "+process.env.local.VUE_APP_TITLE);        

        return process.env.VUE_APP_BASE_URL;
        //return BaseModel.$http.baseURL;
    }



    request(config) {
        // Request interceptors for API calls
        /*this.$http.interceptors.request.use(
            config => {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );*/


        const isFormData = config.data instanceof FormData

        if (isFormData && config.method === 'PUT') {
            config.method = 'POST'
            config.data.append('_method', 'PUT')
        }

        return this.$http.request(config);
    }

    formData() {
        return { indices: true };
    }
}