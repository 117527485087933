<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100% - 220px)"
  >
    <el-row>
      <el-col :span="24"><h1>Lista de Alunos</h1></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <el-button size="small" type="primary" @click="criarAluno()"
            >Criar Aluno</el-button
          >

          <!--<template v-if="alunosVazio">
              <div class="empty-data">
                <el-image :src="emptyImage" class="empty-data-image"></el-image>
              </div>
            </template>-->
          <el-table
            class="el-table"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :data="
              alunos
              /*alunos.filter(
                  (data) =>
                    !search ||
                    data.nome.toLowerCase().includes(search.toLowerCase())
                )*/
            "
          >
            <el-table-column label="Cód." prop="id" width="100" />
            <el-table-column label="Nome" prop="nome" width="250" />
            <el-table-column label="Dt.Nasc" prop="dtnasc" :formatter="formatterDtNasc" width="100" />
            <el-table-column label="Idade" prop="idade_ano" width="80" />
            <el-table-column label="RG" prop="rg" width="150" />
            <el-table-column
              label="Ativo"
              prop="ativo"
              :formatter="formatterAtivo"
              width="100"
            />
            <el-table-column align="right">
              <template #header>
                <el-input
                  v-model="search"
                  size="small"
                  placeholder="Digite o nome do aluno (Enter para Pesquisar)"
                  v-on:keyup.enter="pesquisarAlunos(1)"
                />
              </template>
              <template #default="scope">
                <el-button size="small" @click="editar(scope.row.id)"
                  >Editar</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="openModalExclusao(scope.row.id)"
                  >Excluir</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="count_registros"
            :page-size="15"
            class="mt-4"
            @current-change="trocarPagina"
          />
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span>Deseja realmente excluir este Aluno? {{ alunoSelected.nome }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="remover()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import AlunoModel from "@/models/AlunoModel";
import StatusCad from "@/utils/statusCad";
import { ElLoading } from "element-plus";
import moment from "moment";

export default {
  name: "AlunoList",
  mixins: [ToastMixin],
  components: [ElLoading],

  data() {
    return {
      search: "",
      alunos: [],
      loading: false,
      count_registros: 0,
      meta: {
        pagination: {
          count: 0,
          current_page: 1,
        },
      },
      alunoSelected: [],
      modalExclusaoVisible: false,
      emptyImage: require("@/assets/images/empty-school.svg"),
      status: StatusCad,
      optionsList: [
        { value: null, text: "Selecione algum status" },
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.pesquisarAlunos(this.meta.pagination.current_page);
    this.loading = false;
  },
  methods: {
    async pesquisarAlunos(pagina) {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

      let response = await AlunoModel.params({
        page: pagina,
        nome: this.search,
      }).get();
      this.alunos = response.data;
      this.meta = response.meta;
      this.count_registros = this.meta.pagination.total;
      loadingInstance.close();
    },
    trocarPagina(pagina) {
      this.pesquisarAlunos(pagina);
    },
    criarAluno() {
      this.$router.push({ name: "aluno-create" });
    },
    formatterDtNasc(row) {
      return moment(row.dtnasc).format("DD/MM/YYYY");      
    },    
    formatterAtivo(row) {
      return row.ativo ? "Sim" : "Não";
    },
    editar(AlunoId) {
      this.$router.push({ name: "aluno-edit", params: { id: AlunoId } });
    },
    async openModalExclusao(AlunoId) {
      this.alunoSelected = await AlunoModel.find(AlunoId);
      this.modalExclusaoVisible = true;
    },
    async remover() {
      await this.alunoSelected
        .delete()
        .then((retorno) => {
          this.pesquisarAlunos(this.meta.pagination.current_page);
          this.showToast("success", "", retorno.data.message);
        })
        .catch((error) => {
          this.showToast("error", "", error.response.data.message);
        })
        .finally(() => {
          this.modalExclusaoVisible = false;
        });
    },
    async updateStatus(AlunoId, status) {
      let aluno = await AlunoModel.find(AlunoId);
      aluno.status = status;
      await aluno.save();

      this.alunos = await AlunoModel.params({
        status: [this.status.ATIVO],
      }).get();

      this.showToast("success", "", "Aluno inativado com sucesso");
    },
    tableRowClassName({ row }) {
      if (row.status === 0) {
        return "success-row";
      } else if (row.status === 1) {
        return "warning-row";
      }
      return "";
    },
  },
  computed: {
    alunosVazio() {
      return this.alunos.length === 0 && this.loading == false;
    },
  },
};
</script>

<style>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
