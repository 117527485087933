<template>  
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">     
      <div class="grid-content bg-purple-light"></div>
        <div class="grid-content bg-purple-dark">
          <div class="app-container">            
            <el-form ref="formRef" :model="formModel" :rules="rules" label-width="120px" style="max-width: 460px">              
              <el-row>
                  <el-col :xs="24" :offset="6"> 
                    <h1>Cadastro de Escola</h1>
                  </el-col>
                  <el-col :xs="24">              
                    <el-form-item label="Escola" prop="nome">
                      <el-input v-model="formModel.nome"></el-input>
                    </el-form-item>                
                  </el-col>
                <el-col :xs="24">              
                  <el-form-item label="Apelido" prop="apelido">
                    <el-input v-model="formModel.apelido"></el-input>
                  </el-form-item>                
                </el-col>   
                <el-col :xs="24">              
                  <el-form-item label="Fone 1" prop="fone1">
                    <el-input v-model="formModel.fone1" v-mask="'(##) ####-####'"></el-input>
                  </el-form-item>                
                </el-col>                                  
                <el-col :xs="24">              
                  <el-form-item label="Fone 2" prop="fone2">
                    <el-input v-model="formModel.fone2" v-mask="'(##) ####-####'" ></el-input>
                  </el-form-item>                
                </el-col>   
                
                <el-col :xs="24">
                  <el-form-item label="Ativo" prop="ativo">
                    <el-select v-model="formModel.ativo" placeholder="Select">
                      <el-option
                        v-for="item in optionsList"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>         
                </el-col>
                <el-col :xs="24">
                  <el-form-item>
                    <el-button type="primary"  @click="onSubmit('formRef')">Salvar</el-button>
                    <el-button  @click="testePushRouter">Cancelar</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>    
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import EscolaModel from "@/models/EscolaModel";
import StatusCad from "@/utils/statusCad";
/*import formatStrings from "@/utils/formatStrings";*/

export default {
  name: "Escola",
  mixins: [ToastMixin],
  data() {
    return {
      formModel: {
        nome: "",
        ativo: StatusCad.ATIVO,
      },
      rules: {
        nome: [
          {
            required: true,
            message: 'Preencha o Nome da Escola',
            trigger: "blur",
          },
        ],
      },
      methodSave: "new",
      optionsList: [
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.methodSave = "update";
      this.formModel = await EscolaModel.find(this.$route.params.id);

      //let schools = JSON.parse(localStorage.getItem("schools"));
      //this.formModel =schools[this.$route.params.index];
    }
  },
  methods: {
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //let schools = (localStorage.getItem("schools")) ? JSON.parse(localStorage.getItem("schools")) : [];

          let school = [];
          if (this.methodSave === "update") {
            //schools[this.$route.params.index] = this.formModel;
            //await this.formModel.save();
            school = this.formModel;
          } else {
            //schools.push(this.formModel);
            //const school = new EscolaModel(this.formModel);
            school = new EscolaModel(this.formModel);
            //await school.save();
          } 


          await school
            .save()
            .then(() => {
              this.showToast("success", "", "Escola salva com sucesso");
              this.$router.push({ name: "escola-list" });
            })
            .catch((error) => {
              console.log("deu erro " + error);
            });

          //localStorage.setItem("schools", JSON.stringify(schools));
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    testePushRouter() {      
      this.$router.push({ name: "escola-list" });
    },
    /*aplicarMascaraFoneFixo(texto){     
        this.formModel.fone2=formatStrings.formatarTelefoneFixo(texto);        
    },
    limparMask(texto){
      return texto.replace(/[^0-9]+/g,'');      
    }*/

  },
};
</script>

<style>
</style>