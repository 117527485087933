<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">
    <el-form
      ref="formRef"
      :model="formModel"
      :rules="rules"
      label-width="130px"
      style="max-width: 460px"
    >
      <el-row>
        <el-col :xs="24">
          <h1>Cadastro de Aluno</h1>
        </el-col>
        <el-col :xs="24">
          <el-form-item label="Aluno" prop="nome">
            <el-input v-model="formModel.nome"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24">
          <el-form-item label="RG" prop="rg">
            <el-input v-model="formModel.rg"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24">
          <el-form-item label="Data Nascimento" prop="dtnasc">
            <el-date-picker
              v-model="formModel.dtnasc"
              type="date"
              format="DD/MM/YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Selecione a data de nascimento"
            />
          </el-form-item>
        </el-col>

        <el-col :xs="24">
          <el-form-item label="Ativo" prop="ativo">
            <el-select v-model="formModel.ativo" placeholder="Select">
              <el-option
                v-for="item in optionsList"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">      
          <el-upload
            :file-list="fileList"
            :show-file-list="true"
            ref="upload"
            accept=".jpg, .jpeg"
            action="#"
            list-type="picture-card"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            :class="{ hideUpload: !showUpload }"
            :on-preview="handlePictureCardPreview"
            :on-change="handleChange"
            :on-remove="handleRemove"
            style="margin: 0px auto 20px auto; width: 148px; height: 148px"
          >

                    
            <el-icon>
              <Plus />
            </el-icon> 

            <!--
            <template #file="{ file }">
              <div>
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions"> -->
                <!--  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <el-icon><zoom-in /></el-icon>
                  </span>-->
                <!--  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <el-icon><Download /></el-icon>
                  </span> -->
                  <!--
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <el-icon><Delete /></el-icon>
                  </span>-->
              <!--
                </span>
              </div>
            </template>  -->
          </el-upload>
        </el-col>

        <el-col :xs="24">
          <!--<img style="width: 100px; height: 100px" class="card_image" :src="formModel.image_path" alt="instruments"/>-->
          <el-form-item>
            <el-button type="primary" @click="onSubmit('formRef')"
              >Salvar</el-button
            >
            <el-button @click="testePushRouter">Cancelar</el-button>            
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  <el-dialog v-model="previewVisible">
    <img style="width: 100%" :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>      
  </el-main>
</template>

<script>
import { Plus } from "@element-plus/icons-vue";
import ToastMixin from "@/mixins/toastMixin";
import AlunoModel from "@/models/AlunoModel";
import StatusCad from "@/utils/statusCad";
import api from "@/services/api.js";
import { getAccessTokenAsParam } from "@/utils/auth";
import { ElLoading  } from "element-plus";

export default {
  name: "Aluno",
  components: {            
    Plus    
  },
  mixins: [ToastMixin],
  data() {
    return {
      previewVisible: false,
      dialogImageUrl: "",
      fileList: [],
      showUpload: true,
      disabled: false,
      formModel: {
        nome: "",
        rg: "",
        ativo: StatusCad.ATIVO,
        dtnasc: "",
        image_path: null,
        thumbnail_path: null,
        imageBlob: null,
      },
      rules: {
        nome: [
          {
            required: true,
            message: "Preencha o Nome do Aluno",
            trigger: "blur",
          },
        ],
        rg: [
          {
            required: true,
            message: "Preencha o RG do Aluno",
            trigger: "blur",
          },
        ],
        dtnasc: [
          {
            required: true,
            message: "Preencha a Data de nascimento do Aluno",
            trigger: "blur",
          },
        ],
      },
      methodSave: "new",
      optionsList: [
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.methodSave = "update";
      this.formModel = await AlunoModel.find(this.$route.params.id);

      if (this.formModel.image_path) {
        this.showUpload = false;
        let token = getAccessTokenAsParam();
        this.fileList = [
          {
            name: "aluno.jpeg",
            url: this.formModel.image_path + token,
          },
        ];
      }

      //let alunos = JSON.parse(localStorage.getItem("alunos"));
      //this.formModel =alunos[this.$route.params.index];
    }
    //this.getImageBlob(56);
  },
  methods: {
    async handleDownload(file){            
        const response = await api.get(file.url, {baseURL:"", responseType: "blob" });
        const blob = new Blob([response.data], { type: "image/jpeg" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = file.name;
        link.click();
        URL.revokeObjectURL(link.href);       
    },
    handlePictureCardPreview(uploadFile){
      this.dialogImageUrl = uploadFile.url;
      this.previewVisible = true;
    },
    handleRemove() {
      console.log("HandleRemove");
      this.showUpload = !this.showUpload;
      this.$refs.upload.clearFiles();
      this.formModel.image_path = null;
      this.formModel.imageBlob = null;
    },
    handleChange(arq) {
      console.log("HandleChange");
      console.log(arq);
      this.formModel.imageBlob = arq.raw;
      this.showUpload = !this.showUpload;
    },
    handlePreview(arq) {
      console.log("handlePreview");
      console.log(arq);
      this.file = arq;
    },
    handleAvatarSuccess(response, uploadFile) {
      console.log(response);
      console.log(uploadFile);
    },
    beforeAvatarUpload(rawFile) {
      if (rawFile.type !== "image/jpeg") {
        this.showToast("error", "", "A foto deve estar no formato JPG!");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.showToast("error", "", "A foto deve ter menos de 2MB!");
        return false;
      }
      return true;
    },
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let loadingInstance = ElLoading.service({
            text: "Aguarde",
            lock: true,
          });

          let aluno = [];
          if (this.methodSave === "update") {
            aluno = this.formModel;
          } else {
            aluno = new AlunoModel(this.formModel);

            let alunoEncontrado = await this.verificarRgExistente(aluno.rg);

            if (alunoEncontrado.length > 0) {
              loadingInstance.close();
              let msg = "";
              for (let i = 0; i < alunoEncontrado.length; i++) {
                msg +=
                  alunoEncontrado[i].id + "-" + alunoEncontrado[i].nome + " - ";
              }
              msg = msg.slice(0, -3);
              this.showToast(
                "error",
                "",
                "RG já cadastrado no(s) aluno(s): " + msg
              );
              return;
            }
          }

          await aluno
            .save()
            .then(() => {
              this.showToast("success", "", "Aluno salvo com sucesso");
              this.$router.push({ name: "aluno-list" });
            })
            .catch((error) => {
              this.showToast("error", "", "Erro ao salvar: " + JSON.stringify(error.response.data.message));
            })
            .finally(()=>{
              loadingInstance.close();
            });

          //localStorage.setItem("alunos", JSON.stringify(alunos));
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    testePushRouter() {      
      this.$router.push({ name: "aluno-list" });
    },
    async verificarRgExistente(rgToPesq) {
      let aluno;

      aluno = await AlunoModel.params({ rg: rgToPesq }).get();

      console.log(
        "Verificar RG Existente rgtopesq: " +
          rgToPesq +
          " aluno encontrado: "           
      );
      
      return aluno.data;
    },
  },
};
</script>


<style>

.el-upload-list__item-status-label{
  display: none !important;
}
.hideUpload > ul >div {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>