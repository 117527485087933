<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <el-form
      ref="formRef"
      :model="formModel"
      :rules="rules"
      label-width="230px"
      style="max-width: 350px"
    >
      <el-row justify="center">
        <el-col :span="12">
          <h1>Parâmetros</h1>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24">
          <el-form-item
            label="Mensagem Obrigado (segundos)"
            prop="vot_tempo_msg_obrigado"
          >            
            <el-input-number v-model="formModel.vot_tempo_msg_obrigado" :min="1" :max="50"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24">
          <el-form-item
            label="Mensagem Aguarde (segundos)"
            prop="vot_tempo_msg_aguarde"
          >
            <el-input-number v-model="formModel.vot_tempo_msg_aguarde" :min="1" :max="50"/>            
          </el-form-item>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col :span="14">
          <el-button type="primary" @click="onSubmit('formRef')"
            >Salvar</el-button
          >
          <el-button @click="backHome">Cancelar</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-main>
</template>

<script>
import ParametroModel from "@/models/ParametroModel";
import ToastMixin from "@/mixins/toastMixin";

export default {
  name: "Parametro",
  mixins: [ToastMixin],
  data() {
    return {
      formModel: {
        vot_tempo_msg_obrigado: 0,
        vot_tempo_msg_aguarde: 0,
      },
      rules: {
        vot_tempo_msg_obrigado: [
          {
            required: true,
            message: 'Campo obrigatório',
            trigger: "blur",
          },
        ],
        vot_tempo_msg_aguarde: [
          {
            required: true,
            message: 'Campo obrigatório',
            trigger: "blur",
          },
        ],        
      },      
    };
  },
  async created() {
    this.formModel = await ParametroModel.find(1);
  },  
  methods: {
    backHome() {
      this.$router.push({ name: "home" });
    },
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {        
          await this.formModel
            .save()
            .then(() => {
              this.showToast("success", "", "Parâmetro salvo com sucesso");
              this.$router.push({ name: "home" });
            })
            .catch((error) => {
              console.log("deu erro " + error);
            });          
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },    
  },
};
</script>

<style>
</style>