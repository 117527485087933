<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100% - 220px)">
    <el-form ref="formRef" :model="filtro" :rules="rules">
      <el-row>
        <el-col :span="24"><h1>Votação</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :xs="24" :sm="6">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"
              :disabled="!hasRole(1)"
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-form-item label="Escola" prop="escola_id">
            <el-select
              v-model="filtro.escola_id"
              clearable
              placeholder="Selecione a Escola"
              :disabled="!hasRole(1)"
            >
              <el-option
                v-for="item in escolaToFilter"
                :key="item.id"
                :label="item.nome"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-form-item label="Ensino" prop="ensino">
            <el-select
              v-model="filtro.ensino"
              clearable
              placeholder="Selecione o Ensino"
            >
              <el-option
                v-for="item in ensinoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="6">
          <el-form-item>
            <el-button type="primary" @click="onSubmit('formRef')"
              >Iniciar Votação</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-main>
</template>

<script>
import { ElLoading } from "element-plus";
import ToastMixin from "@/mixins/toastMixin";
import CandidatoModel from "@/models/CandidatoModel";
import EleicaoModel from "@/models/EleicaoModel";
import EscolaModel from "@/models/EscolaModel";
import StatusCad from "@/utils/statusCad";
import { getSchoolFromUser, hasRole } from '../../utils/auth';

export default {  
  name: "CandidatoList",
  mixins: [ToastMixin],
  components: [ElLoading],
  mounted(){    
  },
  data() {
    return {
      filtro: {
        eleicao_id: null,
        escola_id: null,
        ensino: null,
        ativo: 1
      },
      search: "",
      eleicaoToFilter: [],
      escolaToFilter: [],
      candidatos: [],            
      emptyImage: require("@/assets/images/empty-school.svg"),
      status: StatusCad,
      rules: {
        escola_id: [
          {
            required: true,
            message: "Selecione a Escola",
            trigger: "blur",
          },
        ],
        ensino: [
          {
            required: true,
            message: "Selecione o Ensino",
            trigger: "blur",
          },
        ],
      },      
      optionsList: [
        {
          value: null,
          text: "Selecione algum status",
        },
        {
          value: StatusCad.ATIVO,
          text: "Sim",
        },
        {
          value: StatusCad.INATIVO,
          text: "Não",
        },
      ],
      ensinoToFilter: [
        {
          id: "F",
          descricao: "Fundamental",
        },
        {
          id: "M",
          descricao: "Médio",
        },
      ],
    };
  },
  async created() {   
    await this.getEleicao();
    await this.getEscola();
    this.filtro.escola_id = getSchoolFromUser(); 


    //let response = await CandidatoModel.params({
    //  eleicao_id: this.filtro.eleicao_id,
    //}).get();
    //this.candidatos = response.data;
  },
  methods: {
    hasRole,
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    async getEscola() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

      let response = await EscolaModel.params({
        ativo: 1,
      }).get();
      this.escolaToFilter = response.data;

      loadingInstance.close();
    },
    async filtrarCandidato() {
      let filter = {
        ...this.filtro,
      };

      filter = this.clean(filter);

      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });      

      await CandidatoModel.params(filter).get()
        .then((response)=>{
          this.candidatos = response.data;
        })
        .catch(error => {
          if (error.response) {
            throw new Error(error.response.data.message);
          }else{
            throw new Error(error);
          }            
        });
      loadingInstance.close();
    },
    clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    },    
    formatterEnsino(row) {
      return row.ensino;
    },
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {          
          
          await this.filtrarCandidato().then(()=>{
            if (this.candidatos.length == 0){
              this.showToast("error", "", "Nenhum candidato encontrado");
            }else{
              this.showToast("success", "", this.candidatos.length+" candidato(s)"); 

              this.$router.push({name: 'votacao-inicio',
                  params: {
                      candidatos: JSON.stringify(this.candidatos) 
                  }
              })
            }  
          }).catch(error =>{
            this.showToast("error", "", error);            
          });                           

        } else {
          console.log("error submit!");
          return false;
        }
      });
    },   
  },
};
</script>

<style>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
