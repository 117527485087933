<template>
    <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">
        <h1>Página não encontrada</h1>
    </el-main>
</template>

<script>
export default {
    name: 'PageNotFound',
}
</script>

<style>

</style>
