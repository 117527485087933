<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <el-form ref="formRef" :model="filtro" :rules="rules">
      <el-row>
        <el-col :span="24"><h1>Zerésima</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item label="Escola" prop="escola_id">
            <el-select
              v-model="filtro.escola_id"
              clearable
              placeholder="Selecione a Escola"
            >
              <el-option
                v-for="item in escolaToFilter"
                :key="item.id"
                :label="item.nome"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-form-item label="Ensino" prop="ensino">
            <el-select
              v-model="filtro.ensino"
              clearable
              placeholder="Selecione o Ensino"
            >
              <el-option
                v-for="item in ensinoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>        

        <el-col :md="6" :sm="8" :xs="24">
          <el-form-item>
            <el-button type="primary" @click="onSubmit('formRef')"
              >Gerar</el-button
            >            
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>    
  </el-main>
</template>

<script>
import { ElLoading } from "element-plus";
import api from "@/services/api.js";
import ToastMixin from "@/mixins/toastMixin";
import EleicaoModel from "@/models/EleicaoModel";
import EscolaModel from "@/models/EscolaModel";

//import { writeFile } from 'fs/promises';

export default {
  name: "BoletimUrna", 
  mixins: [ToastMixin], 
  data() {
    return {
      dateTimeBoletim: "",
      filtro: {
        eleicao_id: null,
        escola_id: null,
        ensino: null
      },
      rules: {
        escola_id: [
          {
            required: true,
            message: "Selecione a Escola",
            trigger: "blur",
          },
        ],
        ensino: [
          {
            required: true,
            message: "Selecione o Ensino",
            trigger: "blur",
          },          
        ],
      },
      search: "",            
      eleicaoToFilter: [],
      escolaToFilter: [],      
      ensinoToFilter: [
        {
          id: "F",
          descricao: "Fundamental",
        },
        {
          id: "M",
          descricao: "Médio",
        },
      ],      
    };
  },
  async created() {
    await this.getEleicao();
    await this.getEscola();
  },
  methods: {
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          api({
            url: "escolaeleicao/zeresimaPDFExport",
            params: {
              eleicao_id: this.filtro.eleicao_id,
              escola_id: this.filtro.escola_id,
              ensino: this.filtro.ensino,
            },
            method: "GET",
            /*responseType: "text/html"*/
            responseType: "blob",
          }).then((response) => {              
              /*var myWindow = window.open("", "response", "resizable=yes");
              myWindow.document.write(responseHtml);*/
              let escolaSelecionada = this.escolaToFilter.find(item => item.id === this.filtro.escola_id);              

              let responseHtml = response.data;                                
              let blobURL = URL.createObjectURL(responseHtml);
              let a = document.createElement("a");
              a.download = "Zeresima_"+escolaSelecionada.apelido+"_ensino_"+this.filtro.ensino+".pdf";
              a.href = blobURL;
              //a.target = "_blank";
              a.click();              
              
              //window.open(blobURL);
              //window.open(URL.createObjectURL(response.data));
          })
          .catch( async (error) => {
            console.log(error);

            let errorString = error.response.data;
            if (
                error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') != -1
            ) {
              errorString = JSON.parse(await error.response.data.text());

              this.showToast("error", "", errorString.message);
            }          
          });         
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    async getEscola() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

      let response = await EscolaModel.params({
        ativo: 1,
      }).get();
      this.escolaToFilter = response.data;

      loadingInstance.close();
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  computed: {
    isEscolaEleicaoEmpty() {
      return this.escolaEleicao.length == 0;
    },
  },
};
</script>

<style>

</style>
