<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <div class="app-container">
      <el-form
        ref="formRef"
        :model="formModel"
        :rules="rules"
        label-width="120px"
        style="max-width: 460px"
      >
        <el-row>
          <el-col :xs="24" :offset="6">
            <h1>Cadastro de Candidato</h1>
          </el-col>
          <el-col :xs="24">
            <el-form-item
              label="Eleição"
              prop="escola_eleicao.eleicao_id"
              :rules="{
                required: true,
                message: 'Selecione a Eleição',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="formModel.escola_eleicao.eleicao_id"
                placeholder="Selecione a Eleição"
                :disabled="methodSave == 'update'"
                style="width: 100%" 
              >
                <el-option
                  v-for="item in eleicaoToFilter"
                  :key="item.id"
                  :label="item.descricao"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24">
            <el-form-item
              label="Escola"
              prop="escola_eleicao.escola.nome"
              :rules="{
                required: true,
                message: 'Selecione a Escola',
                trigger: 'blur',
              }"
            >
              <el-select
                value-key="id"
                v-model="formModel.escola_eleicao.escola"
                placeholder="Selecione a Escola"
                :disabled="methodSave == 'update'"
                style="width: 100%"
              >
                <el-option
                  v-for="item in escolaToFilter"
                  :key="item.id"
                  :label="item.nome"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24">
            <el-form-item
              label="Ensino"
              prop="escola_eleicao.ensino"
              :rules="{
                required: true,
                message: 'Selecione o Ensino',
                trigger: 'blur',
              }"
            >
              <el-select
                value-key="id"
                v-model="formModel.escola_eleicao.ensino"
                placeholder="Selecione o Ensino"
                :disabled="methodSave == 'update'"
                style="width: 100%"
              >
                <el-option
                  v-for="item in ensinoToFilter"
                  :key="item.id"
                  :label="item.descricao"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24">
            <el-form-item
              label="Aluno"
              prop="aluno.nome"
              :rules="{
                required: true,
                message: 'Selecione o Aluno',
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="formModel.aluno"
                filterable
                remote
                reserve-keyword
                placeholder="Selecione o Aluno"
                value-key="id"
                :remote-method="getAluno"
                :loading="loading"
                style="width: 100%"
              >
                <el-option
                  v-for="item in alunoToFilter"
                  :key="item.id"
                  :label="item.nome"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24">
            <el-form-item label="Ativo" prop="ativo">
              <el-select v-model="formModel.ativo" placeholder="Select">
                <el-option
                  v-for="item in optionsList"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24">
            <el-form-item>
              <el-button type="primary" @click="onSubmit('formRef')"
                >Salvar</el-button
              >
              <el-button @click="testePushRouter">Cancelar</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-main>
</template>
<!--Parei ajeitando a foto, vou terminar quando tiver fazendo o backend-->
<script>
import ToastMixin from "@/mixins/toastMixin";
import CandidatoModel from "@/models/CandidatoModel";
import StatusCad from "@/utils/statusCad";
import EleicaoModel from "@/models/EleicaoModel";
import EscolaModel from "@/models/EscolaModel";
import AlunoModel from "@/models/AlunoModel";

export default {
  name: "Candidato",
  components: {},
  mixins: [ToastMixin],
  data() {
    return {
      fileList: [],
      showUpload: true,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      teste1: null,
      loading: false,
      formModel: {
        escola_eleicao: {
          eleicao_id: null,
          escola_id: null,
          ensino: null,
          escola: {
            id: null,
            nome: null,
          },
        },
        aluno: {
          nome: null,
        },
        ativo: StatusCad.ATIVO,
        imageBlob: null,
      },
      eleicaoToFilter: [],
      escolaToFilter: [],
      alunoToFilter: [],
      methodSave: "new",
      optionsList: [
        {
          value: StatusCad.ATIVO,
          text: "Sim",
        },
        {
          value: StatusCad.INATIVO,
          text: "Não",
        },
      ],
      ensinoToFilter: [
        {
          id: "F",
          descricao: "Fundamental",
        },
        {
          id: "M",
          descricao: "Médio",
        },
      ],
    };
  },
  async created() {
    await this.getEleicao();
    await this.getEscola();
    if (this.$route.params.id) {
      this.methodSave = "update";
      this.formModel = await CandidatoModel.find(this.$route.params.id);
      this.alunoToFilter.push(this.formModel.aluno);
    }
  },
  methods: {
    onSubmit(formName) {
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //let dados = { ... this.formModel };
          let Candidato = [];
          if (this.methodSave === "update") {
            Candidato = this.formModel;
          } else {
            Candidato = new CandidatoModel(this.formModel);
          }

          Candidato.aluno_id = Candidato.aluno.id;
          Candidato.escola_eleicao.escola_id =
            Candidato.escola_eleicao.escola.id;

          await Candidato.save()
            .then((retorno) => {
              this.showToast("success", "", retorno.message);
              this.$router.push({
                name: "candidato-list",
              });
            })
            .catch((error) => {
              this.showToast(
                "error",
                "",
                "Problema ao salvar registro: " + error
              );
            });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    async getAluno(query) {
      if (query) {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          query = query + "%";
          let response = await AlunoModel.params({
            nome: query,
          }).get();
          this.alunoToFilter = response.data;
        }, 200);
      } else {
        this.alunoToFilter = [];
      }
    },
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();

      this.eleicaoToFilter = response.data;

      this.formModel.escola_eleicao.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    async getEscola() {
      let response = await EscolaModel.params({
        ativo: 1,
      }).get();

      this.escolaToFilter = response.data;
    },
    testePushRouter() {
      this.$router.push({
        name: "candidato-list",
      });
    },
  },
};
</script>

<style>
.hideUpload > div {
  display: none;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
