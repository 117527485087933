<template>
  <el-main>    
    <div class="text-top">Página principal</div>
    
    <ul style="text-align: left">        
     <!-- AFAZERES:            
      <li>Colocar foto dos candidatos na listagem de apuração</li>            
      <li>Verificar porque nao saí papel o timbrado na segunda folha da lista de classificação</li>
      <li>Fazer boletim de Urna com a possibilidade de imprimir</li>            
      <li>Salvar token de outra forma</li>      -->
    </ul>      
  </el-main>
</template>

<script>
import { signOut } from '../utils/auth';
import ToastMixin from "@/mixins/toastMixin";

export default {
  name: "Home",
  mixins: [ToastMixin], 
  created(){    
  },
  methods:{
    logout(){           
        signOut().then(()=>{
          this.$router.push('/login');        
        }).catch((error)=>{
          this.showToast('error','', error);                
        }); 

    }
  }
};
</script>

<style scoped>
main {
  background-color: var(--color-background-home);
  justify-content: center;
  text-align: center;
  max-width: 1200px; 
  margin: 0 auto;  
  height: calc(100% - 220px);  
  
  /*max-height: 100px;*/
  /*calc(100vh - calc(100vh - 100%))*/    
  /*height: calc(100vh - calc(100vh - 100%));*/
}

.text-top {
  color: var(--color-text-dark);
  font-size: 22px;
  margin-bottom: 20px;
}

.text-bottom {
  color: var(--color-text-dark);
  font-size: 18px;
  margin-top: 20px;
}

#subscribe {
  width: 340px;
}

@media (min-width: 700px) {
  #subscribe {
    width: 540px;
  }
}
</style>
