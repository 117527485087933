<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100% - 220px)"
  >
    <el-row>
      <el-col :span="24"><h1>Eleições</h1></el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <el-button size="small" type="primary" @click="criarEleicao()"
            >Criar Eleição</el-button
          >

          <template v-if="isEleicoesEmpty">
            <div class="empty-data">
              <el-image :src="emptyImage" class="empty-data-image"></el-image>
            </div>
          </template>
          <el-table
            v-loading="loading"
            class="el-table"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :data="
              eleicoes.filter(
                (data) =>
                  !search ||
                  data.ano
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
            "
            v-else
          >
            <el-table-column label="Cód." prop="id" width="100" />
            <el-table-column label="Ano" prop="ano" width="120" />
            <el-table-column label="Descrição" prop="descricao" width="200" />
            <el-table-column
              label="Dt.Posse"
              prop="dtposse"
              :formatter="formatterDtPosse"
              width="120"
            />
            <el-table-column
              label="Ativo"
              prop="ativo"
              :formatter="formatterAtivo"
              width="100"
            />
            <el-table-column align="right">
              <template #header>
                <el-input
                  v-model="search"
                  size="small"
                  placeholder="Digite o Ano"
                />
              </template>
              <template #default="scope">
                <el-button size="small" @click="editar(scope.row.id)"
                  >Editar</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="openModalExclusao(scope.row.id)"
                  >Excluir</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span
        >Deseja realmente excluir esta Eleição? {{ eleicaoSelected.nome }}</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="remover()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import EleicaoModel from "@/models/EleicaoModel";
import StatusCad from "@/utils/statusCad";
import moment from "moment";

export default {
  name: "EleicaoList",
  mixins: [ToastMixin],
  data() {
    return {
      search: "",
      loading: false,
      eleicoes: [],
      eleicaoSelected: [],
      modalExclusaoVisible: false,
      emptyImage: require("@/assets/images/empty-school.svg"),
      status: StatusCad,
      optionsList: [
        { value: null, text: "Selecione algum status" },
        { value: StatusCad.ATIVO, text: "Sim" },
        { value: StatusCad.INATIVO, text: "Não" },
      ],
    };
  },
  async created() {
    this.loading = true;
    let data = await EleicaoModel.get();
    this.eleicoes = data.data;
    this.loading = false;
  },
  methods: {
    criarEleicao() {
      this.$router.push({ name: "eleicao-create" });
    },
    formatterAtivo(row) {
      //return this.optionsList[row.ativo+1].text;
      return row.ativo ? "Sim" : "Não";
    },
    formatterDtPosse(row) {
      return moment(row.dtposse).format("DD/MM/YYYY");
    },
    editar(EleicaoId) {
      this.$router.push({ name: "eleicao-edit", params: { id: EleicaoId } });
    },
    async openModalExclusao(EleicaoId) {
      this.eleicaoSelected = await EleicaoModel.find(EleicaoId);
      this.modalExclusaoVisible = true;
    },
    async remover() {
      this.eleicaoSelected
        .delete()
        .then(async (response) => {
          let data = await EleicaoModel.get();
          this.eleicoes = data.data;
          this.showToast("success", "", response.data.message);
        })
        .catch((error) => {
          this.showToast(
            "error",
            "",
            "Problema ao excluir registro: " + error.response.data.message
          );
        })
        .finally(() => {
          this.modalExclusaoVisible = false;
        });
    },
    async updateStatus(EleicaoId, status) {
      let eleicao = await EleicaoModel.find(EleicaoId);
      eleicao.status = status;
      await eleicao.save();

      this.eleicoes = await EleicaoModel.params({
        status: [this.status.ATIVO],
      }).get();

      this.showToast("success", "", "Eleição inativada com sucesso");
    },
    tableRowClassName({ row }) {
      if (row.status === 0) {
        return "success-row";
      } else if (row.status === 1) {
        return "warning-row";
      }
      return "";
    },
  },
  computed: {
    isEleicoesEmpty() {
      return this.eleicoes.length === 0 && this.loading == false;
    },
  },
};
</script>

<style>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
