import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import pt from 'element-plus/es/locale/lang/pt'
import  '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './routes/router'
import { VueMaskDirective } from 'v-mask';


const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

const app = createApp(App)
app.use(ElementPlus,{
    locale: pt,
})
app.use(router)
app.directive('mask', vMaskV3)



app.mount('#app')
