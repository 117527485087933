<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">
		<div style="max-width: 460px; display: flex; flex-direction: row; justify-content: center;">
    <h1>Cadastro de Usuário</h1>
		</div>

    <el-form      
      ref="formRef"
      :model="formModel"
      :rules="rules"
      label-width="140px"
      style="max-width: 460px"
    >
      <el-form-item label="Nome" prop="nome">
        <el-input v-model="formModel.nome"></el-input>
      </el-form-item>
      <el-form-item label="Login" prop="login">
        <el-input
          v-model="formModel.login"
          :disabled="methodSave == 'update'"
        ></el-input>
      </el-form-item>

        <el-form-item label="Senha" prop="password">
          <el-input type="password" v-model="formModel.password"></el-input>       
        </el-form-item>

			<el-form-item label="Confirmar Senha" prop="confirmacao_password">
        <el-input type="password" v-model="formModel.confirmacao_password"></el-input>
      </el-form-item>			

      <el-form-item
        label="Perfil"
        prop="role_id"
        :rules="{
          required: true,
          message: 'Selecione o Perfil do Usuário',
          trigger: 'blur',
        }"
      >
        <el-select
          v-model="formModel.role_id"
          placeholder="Selecione o Perfil do Usuário"
					style="width: 100%"
        >
          <el-option
            v-for="item in usuarioRoleToFilter"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <template v-if="formModel.role_id == 2">
        <el-form-item
          label="Escola Vinculada"
          prop="escola_id"
          :rules="{
            required: true,
            message: 'Selecione a Escola do Usuário',
            trigger: 'blur',
          }"
        >
          <el-select
            v-model="formModel.escola_id"
            placeholder="Selecione a Escola do Usuário"
						style="width: 100%"
          >
            <el-option
              v-for="item in escolaToFilter"
              :key="item.id"
              :label="item.apelido"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="Ativo" prop="ativo">
        <el-select
          v-model="formModel.ativo"
          placeholder="Selecione"
          style="width: 100%"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="salvar('formRef')">Salvar</el-button>
        <el-button @click="testePushRouter">Cancelar</el-button>
      </el-form-item>
    </el-form>
  </el-main>
</template>

<script>
import UsuarioModel from "@/models/UsuarioModel";
import UsuarioRoleModel from "@/models/UsuarioRoleModel";
import EscolaModel from "@/models/EscolaModel";
import ToastMixin from "@/mixins/toastMixin";
import StatusCad from "@/utils/statusCad";

export default {
  name: "Usuario",
  mixins: [ToastMixin],
  data() {
    return {
      methodSave: "new",
      optionsList: [
        {value: StatusCad.ATIVO, text: "Sim"},
        {value: StatusCad.INATIVO,text: "Não"},
      ],
      usuarioRoleToFilter: [],
      escolaToFilter: [],			
      formModel: {
        nome: "",
        login: "",
        password: "",			
				confirmacao_password: "",	
        ativo: StatusCad.ATIVO,
        role_id: null,
        escola_id: null,
      },
      rules: {
        nome: [
          {
            required: true,
            message: "Preencha o nome",
          },
        ],
        login: [
          {
            required: true,
            message: "Preencha o Login",
          },
        ],
				confirmacao_password: [
					{            
            validator: this.validarConfirmacaoSenha,
            trigger: "blur",
					}
				],
        password: [
          {
            //required: this.methodSave != "update",
						//validator: this.validarSenha,
            //message: "Preencha o Senha",
            validator: this.validarSenha,
            trigger: "blur",
          },
        ],
      },
    };
  },
  async created() {
    await this.getUsuarioRole();
    await this.getEscola();
    if (this.$route.params.id) {
      this.methodSave = "update";			
      this.formModel = await UsuarioModel.find(this.$route.params.id);
			this.formModel.confirmacao_password = this.formModel.password;
    }
  },
  methods: {
    async getUsuarioRole() {
      let response = await UsuarioRoleModel.get();

      this.usuarioRoleToFilter = response.data;
    },
    async getEscola() {
      let response = await EscolaModel.get();

      this.escolaToFilter = response.data;
    },
    salvar(formName) {
      if (!this.$refs[formName]) return;

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let usuario = [];
          if (this.methodSave === "update") {
            usuario = this.formModel;
          } else {
            usuario = new UsuarioModel(this.formModel);
          }

          await usuario
            .save()
            .then(() => {
              this.showToast("success", "", "Usuário salvo com sucesso");
              this.$router.push({
                name: "usuario-list",
              });
            })
            .catch((error) => {	              
              let message = error.response.data.errors.password ? error.response.data.errors.password : error.response.data.message;                           					
              this.showToast("error", "", "Erro ao salvar: " + message);
            });
        } else {
          return false;
        }
      });
    },
    onSubmit(formName) {      
      if (!this.$refs[formName]) return;
      this.$refs[formName].validate((valid) => {
        if (valid) {         
          //const user = new UserModel(this.formLogin);

          let users = localStorage.getItem("users")
            ? JSON.parse(localStorage.getItem("users"))
            : [];
          users.push(this.formModel);

          localStorage.setItem("users", JSON.stringify(users));
          //this.$router.push({name:'usuario-list'});
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    validarSenha(rule, value, callback) {      
      if (value === "" && this.methodSave !== "update") {
        callback(new Error("Preencha a senha"));      
			} else {
        callback();
      }
    },
    validarConfirmacaoSenha(rule, value, callback) {   
      if (!value  && this.formModel.password) {
        callback(new Error("Preencha a confirmação de password"));
      } else if (value && value !== this.formModel.password){
				callback(new Error("Confirmação de password inválida"));	
			} else {
        callback();
      }
    },
    testePushRouter() {
      console.log(this.$router);
      this.$router.push("/usuario");
    },
  },
};
</script>

<style>
</style>
