<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">
    <el-form>
      <el-row>
        <el-col :span="24"><h1>Eleição na Escola</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :xs="24" :sm="6">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              @change="filtrarEscolaEleicao()"
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">        
        <el-table
          v-loading="loading"
          class="el-table"
          style="width: 100%"            
          :data="escolaEleicao"          
        >            
          <el-table-column label="Escola" prop="escola.apelido" width="200" />            
          <el-table-column label="Ensino" prop="ensino" width="80"/>               
          <el-table-column
            label="Nº de Candidato(s)"              
            :formatter="formatterQtdeCandidato"
            width="120"
          />
          <el-table-column label="Permite Voto"  width="120">
            <template #default="scope">
              <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.permite_voto" @change="updatePermiteVoto(scope.row)"/>                
            </template>              
          </el-table-column>   
        </el-table>        
      </el-col>
    </el-row>

    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span
        >Deseja realmente excluir este EscolaEleicao?
        {{ escolaEleicaoSelected.nome }}</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="remover()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import EscolaEleicaoModel from "@/models/EscolaEleicaoModel";
import EleicaoModel from "@/models/EleicaoModel";
import StatusCad from "@/utils/statusCad";
import api from "@/services/api.js";

export default {
  name: "EscolaEleicaoList",
  mixins: [ToastMixin],
  data() {
    return {
      filtro: {
        eleicao_id: null
      },
      loading: true,
      search: "",
      escolaEleicao: [],
      eleicaoToFilter: [],          
      escolaEleicaoSelected: [],
      modalExclusaoVisible: false,      
      status: StatusCad,
      optionsList: [
        {
          value: null,
          text: "Selecione algum status",
        },
        {
          value: StatusCad.ATIVO,
          text: "Sim",
        },
        {
          value: StatusCad.INATIVO,
          text: "Não",
        },
      ],      
    };
  },
  async created() {
    this.loading = true;
    await this.getEleicao();    
    let response = await EscolaEleicaoModel.params({
      eleicao_id: this.filtro.eleicao_id,
    }).get();
    this.escolaEleicao = response.data;
    this.loading = false;
  },
  methods: {
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },    
    async filtrarEscolaEleicao() {
      let filter = {
        ...this.filtro,
      };

      filter = this.clean(filter);

      if (filter["ensino"] === "") delete filter["ensino"];

      if (filter["escola_id"] === "") delete filter["escola_id"];

      let response = await EscolaEleicaoModel.params(filter).get();
      this.escolaEleicao = response.data;
    }, 
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },    
    formatterQtdeCandidato(row) {
      return row.candidato.length;
    },    
    editar(EscolaEleicaoId) {
      this.$router.push({
        name: "escolaEleicao-edit",
        params: {
          id: EscolaEleicaoId,
        },
      });
    },
    async openModalExclusao(EscolaEleicaoId) {
      this.escolaEleicaoSelected = await EscolaEleicaoModel.find(EscolaEleicaoId);
      this.modalExclusaoVisible = true;
    },
    async remover() {
      await this.escolaEleicaoSelected
        .delete()
        .then(async (retorno) => {
          await this.filtrarEscolaEleicao();
          this.showToast("success", "", retorno.data.message);
          this.modalExclusaoVisible = false;
        })
        .catch((error) => {
          this.showToast("error", "", "Problema ao excluir registro: " + error);
        });
    },
    async updatePermiteVoto(row) {          
        api({
          url: "escolaeleicao/"+row.id,
          data: row,
          method: "PUT",
          responseType: "text",        
        }).catch((error)=>{
          row.permite_voto = row.permite_voto == 1 ? 0 : 1;
          this.showToast("error", "", "Erro: " + error);
        });      
      
      //await EscolaEleicaoModel.save();                
        /*.catch(error =>{
          //console.log(error);
          this.showToast("error", "", "Erro: " + error);
        })*/          
      /*let escolaEleicao = await EscolaEleicaoModel.find(escolaEleicaoId);
      escolaEleicao.status = status;
      await escolaEleicao.save();

      let response = await EscolaEleicaoModel.params({
        status: [this.status.ATIVO],
      }).get();
      this.escolaEleicao = response.data;*/

      //this.showToast("success", "", "EscolaEleicao inativado com sucesso");       
    },    
  },
  computed: {
    isEscolaEleicaoEmpty() {
      return this.escolaEleicao.length === 0 && this.loading == false;
    },
  },
};
</script>

<style>



</style>
