<template>
    <main>        
        <section class="container">
            <h1>Sobre</h1>
            <div class="about" v-html="sobre.about">    
                
            </div>             
        </section>
    </main>
</template>


<script>
import api from '@/services/api.js';

export default {
    name: 'Sobre',
    data(){
        return{
            sobre: []
        }
    },
    mounted(){
        api.get('/about.json').then(response =>{            
            this.sobre = response.data;  
            console.log(response.data);          
        })
    }    
}




</script>

<style scoped>
    main{
        align-items: center;
    }

    .about{
        margin-bottom: 40px;
        color: var(--color-text-dark);
    }
  


</style>
