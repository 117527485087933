<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">
    <el-form>
      <el-row>
        <el-col :span="24"><h1>Candidatos</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :xs="24" :sm="6">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              @change="filtrarCandidato()"
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-form-item label="Escola" prop="escola_id">
            <el-select
              @change="filtrarCandidato()"
              v-model="filtro.escola_id"
              clearable
              placeholder="Todas"
            >
              <el-option
                v-for="item in escolaToFilter"
                :key="item.id"
                :label="item.nome"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="6">
          <el-form-item label="Ensino" prop="ensino">
            <el-select
              @change="filtrarCandidato()"
              v-model="filtro.ensino"
              clearable
              placeholder="Todos"
            >
              <el-option
                v-for="item in ensinoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!--
			<el-col :xs="6" :sm="8">
				<el-form-item>
					<el-button type="primary" @click="teste()" size="small">Buscar</el-button>
					<el-button type="primary" @click="limparFiltros()" size="small">Limpar Filtro</el-button>
				</el-form-item>
			</el-col>-->
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <el-button size="small" type="primary" @click="criarCandidato()"
            >Novo Candidato</el-button
          >

          <!--
          <template v-if="isCandidatosEmpty">
            <div class="empty-data">
              <el-image :src="emptyImage" class="empty-data-image"></el-image>
            </div>
          </template>
          -->
          <el-table
            v-loading="loading"
            class="el-table"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :data="
              candidatos.filter(
                (data) =>
                  !search ||
                  data.aluno.nome.toLowerCase().includes(search.toLowerCase())
              )
            "          
          >
            <el-table-column label="Cód." prop="id" width="70" />
            <el-table-column label="Foto"  width="80">
              <template #default="scope">
              <div class="block">
                <el-avatar shape="square" :size="50" :src="getImage(scope.row)" />
              </div>                                
              </template>              
            </el-table-column> 

            <el-table-column label="Nome" prop="aluno.nome" width="280" />
            <el-table-column label="Dt.Nasc" prop="aluno.dtnasc" :formatter="formatterDtNasc" width="100" />
            <el-table-column label="Idade" prop="aluno.idade_ano" width="100" />
            <el-table-column
              label="Escola"
              prop="escola_eleicao.escola.apelido"
              width="200"
            />
            <el-table-column
              label="Ensino"
              prop="escola_eleicao.ensino"
              :formatter="formatterEnsino"
              width="80"
            />
            <el-table-column
              label="Ativo"
              prop="ativo"
              :formatter="formatterAtivo"
              width="80"
            />
            <el-table-column align="right">
              <template #header>
                <el-input
                  v-model="search"
                  size="small"
                  placeholder="Digite o Nome do Aluno"
                />
              </template>
              <template #default="scope">
                <el-button size="small" @click="editar(scope.row.id)"
                  >Editar</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="openModalExclusao(scope.row.id)"
                  >Excluir</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>

    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span
        >Deseja realmente excluir este Candidato?
        {{ candidatoSelected.nome }}</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="remover()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import CandidatoModel from "@/models/CandidatoModel";
import EleicaoModel from "@/models/EleicaoModel";
import EscolaModel from "@/models/EscolaModel";
import StatusCad from "@/utils/statusCad";
import moment from "moment";
import { getAccessTokenAsParam } from "@/utils/auth";

export default {
  name: "CandidatoList",
  mixins: [ToastMixin],
  data() {
    return {
      filtro: {
        eleicao_id: null,
        escola_id: null,
        ensino: null,
      },
      loading: true,
      search: "",
      eleicaoToFilter: [],
      escolaToFilter: [],
      candidatos: [],
      candidatoSelected: [],
      modalExclusaoVisible: false,
      //emptyImage: require("@/assets/images/empty-school.svg"),
      candidato_image: require("@/assets/images/candidato.png"),
      status: StatusCad,
      optionsList: [
        {
          value: null,
          text: "Selecione algum status",
        },
        {
          value: StatusCad.ATIVO,
          text: "Sim",
        },
        {
          value: StatusCad.INATIVO,
          text: "Não",
        },
      ],
      ensinoToFilter: [
        {
          id: "F",
          descricao: "Fundamental",
        },
        {
          id: "M",
          descricao: "Médio",
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.getEleicao();
    await this.getEscola();
    let response = await CandidatoModel.params({
      eleicao_id: this.filtro.eleicao_id,
    }).get();
    this.candidatos = response.data;
    this.loading = false;
  },
  methods: {
    getImage(item) {
      if (item.aluno.thumbnail_path != null) {
        let token = getAccessTokenAsParam();
        return item.aluno.thumbnail_path + token;
      } else return this.candidato_image;
    },    
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    async getEscola() {
      let response = await EscolaModel.params({
        ativo: 1,
      }).get();
      this.escolaToFilter = response.data;
    },
    async filtrarCandidato() {
      let filter = {
        ...this.filtro,
      };

      filter = this.clean(filter);

      if (filter["ensino"] === "") delete filter["ensino"];

      if (filter["escola_id"] === "") delete filter["escola_id"];

      let response = await CandidatoModel.params(filter).get();
      this.candidatos = response.data;
    },
    formatterDtNasc(row) {
      return moment(row.aluno.dtnasc).format("DD/MM/YYYY");      
    },       
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    criarCandidato() {
      this.$router.push({
        name: "candidato-create",
      });
    },
    formatterEnsino(row) {
      return row.escola_eleicao.ensino;
    },
    formatterAtivo(row) {
      // return this.optionsList[row.ativo + 1].text;
      return row.ativo ? "Sim" : "Não";
    },
    editar(CandidatoId) {
      this.$router.push({
        name: "candidato-edit",
        params: {
          id: CandidatoId,
        },
      });
    },
    async openModalExclusao(CandidatoId) {
      this.candidatoSelected = await CandidatoModel.find(CandidatoId);
      this.modalExclusaoVisible = true;
    },
    async remover() {
      await this.candidatoSelected
        .delete()
        .then(async (retorno) => {
          await this.filtrarCandidato();
          this.showToast("success", "", retorno.data.message);
          this.modalExclusaoVisible = false;
        })
        .catch((error) => {
          this.showToast("error", "", "Problema ao excluir registro: " + error);
        });
    },
    async updateStatus(CandidatoId, status) {
      let candidato = await CandidatoModel.find(CandidatoId);
      candidato.status = status;
      await candidato.save();

      let response = await CandidatoModel.params({
        status: [this.status.ATIVO],
      }).get();
      this.candidatos = response.data;

      this.showToast("success", "", "Candidato inativado com sucesso");
    },
    tableRowClassName({ row }) {
      if (row.status === 0) {
        return "success-row";
      } else if (row.status === 1) {
        return "warning-row";
      }
      return "";
    },
  },
  computed: {
    isCandidatosEmpty() {
      return this.candidatos.length === 0 && this.loading == false;
    },
  },
};
</script>

<style>
.el-avatar{
  --el-avatar-bg-color: white;
}

.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
