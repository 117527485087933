<template>
  <div id="app" v-cloak style="height: 100%">       
      <Header2 v-if="showHeaderFooter"/>
      <!--<transition name="fade" mode="out-in">-->
        <router-view path="$router.key"/>      
      <!--</transition> -->        
      <Footer v-if="showHeaderFooter"/>        
  </div>
</template>

<script>
import './styles/global.css';
import Header2 from './components/Header2';
import Footer from './components/Footer';


export default {
  computed: {
    showHeaderFooter(){      
      return !String("login").includes(this.$route.name);
    }
  },
  name: 'App',
  components: {
    Header2,
    Footer        
  },
  methods:{
  }
}
</script>

<style>
  [v-cloak] {display: none;}
  [v-cloak]::before { content: "loading..."; }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
