import decode from 'jwt-decode';
import api from "@/services/api";


export function getSchoolFromUser() {
  let credenciais = JSON.parse(localStorage.getItem('credenciais'));
  if (credenciais)
    return credenciais.user.escola_id
  else
    return null;
}

export function getAccessTokenAsParam() {
  return "?token=" + getAccessToken();
}

export function getAccessToken() {  
  let credenciais = localStorage.getItem('credenciais');
  if (credenciais)
    return JSON.parse(credenciais)['access_token'];
  else
    return '';

  /*if (isSignedIn()){
    let credenciais = JSON.parse(localStorage.getItem('credenciais'));      
    return credenciais['access_token'];
  }else{
    return '';
  }  */
}

export function getLoggedUser() {  
  let credenciais = localStorage.getItem('credenciais');
  if (credenciais)
    return JSON.parse(credenciais).user.login;
  else
    return '';

  /*if (isSignedIn()){
    let credenciais = JSON.parse(localStorage.getItem('credenciais'));      
    return credenciais.user.login;
  }else{
    return '';
  }*/
}

function getUserRole() {
  let credenciais = JSON.parse(localStorage.getItem('credenciais'));
  if (credenciais)
    return credenciais.user.role_id;
}

export function hasRole(role) {
  let userRole = getUserRole();
  if (userRole == role)
    return true
  else
    return false;
}

export async function signIn(login, password) {
  await api.post('login', { login, password }).then((response) => {
    const credenciais = JSON.stringify(response.data);
    localStorage.setItem('credenciais', credenciais);
  });
}

export async function signOut() {
  await api.get('logout').then(() => {
    localStorage.removeItem('credenciais');
  }).catch((error) => {
    localStorage.removeItem('credenciais');
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error(error);
    }
  });
}

export async function isSignedIn() {  
  let credenciais = JSON.parse(localStorage.getItem('credenciais'));

  const token = (credenciais) ? (credenciais['access_token']) : null;

  if (!token)     // Se não existe o token no LocalStorage
    return false; // significa que o usuário não está assinado.

  try {
    const { exp: expiration } = decode(token);
    const isExpired = !!expiration && Date.now() > expiration * 1000;

    if (isExpired) {  // Se o token tem uma data de expiração e
      console.log("Token expirado");
      try {
        let response = await refreshToken();        
        if (response){  
          return true 
        }else{ 
            localStorage.removeItem('credenciais');
            return false 
          }
        /*refreshToken()
          .then(() => { return true })
          .catch(() => { 
            localStorage.removeItem('credenciais');
            return false });*/
      } catch {
        localStorage.removeItem('credenciais');
        return false;
      }
    }else{
      return true;
    }
  } catch (_) {   // O "jwt-decode" lança erros pra tokens inválidos.
    return false; // Com um token inválido o usuário não está assinado.
  }
}


export async function refreshToken(error) {
  return new Promise((resolve, reject) => {
    try {
      /*const refresh_token = localStorage.getItem("refresh_token");
      const header = {
        "Content-Type": "application/json",
        Authorization: process.env.AUTHORIZATION,
      };
      const parameters = {
        method: "POST",
        headers: header,
      };
      const body = {
        grant_type: "refresh_token",
        refresh_token,
      };*/
      api.post("refresh"/*, body, parameters*/).then(async (response) => {
        const credenciaisNova = JSON.parse(JSON.stringify(response.data));

        let credenciais = JSON.parse(localStorage.getItem('credenciais'));
        credenciais.access_token = credenciaisNova['access_token'];
        credenciais.expires_in = credenciaisNova['expires_in'];

        localStorage.setItem('credenciais', JSON.stringify(credenciais));
        // Fazer algo caso seja feito o refresh token
        return resolve(response);
      }).catch((err) => {
        // Fazer algo caso não seja feito o refresh token
        console.log(err);        
        return reject(error);
      });
    } catch (err) {
      return reject(err);
    }
  });
}