<template>
  <el-main
    style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)"
  >
    <el-form ref="formRef" :model="filtro">
      <el-row class="no-print">
        <el-col :span="24"><h1>Selecione a eleição</h1></el-col>
      </el-row>
      <el-row el-row :gutter="20">
        <el-col :xs="12" :sm="8" :md="6">
          <el-form-item label="Eleição" prop="eleicao_id">
            <el-select
              v-model="filtro.eleicao_id"
              placeholder="Selecione a Eleição"              
            >
              <el-option
                v-for="item in eleicaoToFilter"
                :key="item.id"
                :label="item.descricao"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="6">
          <el-form-item>
            <el-button class="no-print" type="primary" @click="getResultado">Apurar Resultados</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row>
      <el-col :span="24">
        <el-tabs
          type="border-card"
          class="demo-tabs"
          v-if="!isEscolaEleicaoEmpty"
        >
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <span>Resultados</span>
              </span>
            </template>

            <el-row :gutter="20">
              <el-col
                v-for="item in escolaEleicao"
                :key="item.id"
                :span="24"
                style="margin-right: 0px"
              >
                <el-card
                  :body-style="{ padding: '0px' }"
                  shadow="hover"
                  style="margin-bottom: 30px"
                >
                  <div style="padding: 14px">
                    <el-row>
                      <el-col :span="24">
                        <b>
                          {{ item.escola.nome }} - Ensino {{ item.ensino }}</b
                        >
                      </el-col>
                      <el-col :span="24" style="margin-top: 10px">
                        <b>Votos Total: </b> {{ item.votos_total }}
                      </el-col>
                      <el-col :span="24" style="margin-top: 10px">
                        <b>Votos Nulo: </b> {{ item.votos_nulo }}
                      </el-col>
                    </el-row>
                    <div class="demo-progress">
                      <div
                        class="candidato"
                        v-for="item2 in item.candidato"
                        :key="item2.id"
                        style="margin-top: 10px"
                      >
                        
                        <div class="candidato-avatar">
                          <el-avatar shape="square" :size="50" :src="getImage(item2)" />
                        </div>                
                        <div class="candidato-conteudo">
                          <span
                            >{{ item2.aluno.nome }} - <b>Votos: </b
                            >{{ item2.votos }}
                          </span>
                          <el-progress
                            :text-inside="true"
                            :stroke-width="26"
                            :percentage="item2.porcentagem"
                          />                          
                        </div>                                      
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Eleitos">
            <el-dropdown style="float: right" @command="exportarWord" class="no-print">
              <el-button>
                Exportar<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="docx"
                    ><el-icon class="el-icon--right"><Document /></el-icon
                    >ODT</el-dropdown-item
                  >
                  <el-dropdown-item command="pdf"
                    ><el-icon class="el-icon--right"><Document /></el-icon
                    >PDF</el-dropdown-item
                  >                                                      
                </el-dropdown-menu>
              </template>
            </el-dropdown>            

            <el-table
              class="el-table"
              style="width: 100%"
              :row-class-name="tableRowClassName"
              :data="listaClassificacao"
            >
              <el-table-column
                label="Classif."
                prop="classificacao"
                width="100"                
              >                  
                <template #default="scope">
                  <div class="classificacao-avatar">
                    <b>{{scope.row.classificacao}}</b>  
                    <div class="block">
                      <el-avatar shape="square" :size="50" :src="getImageClassificacao(scope.row)" />
                    </div>                                                    
                  </div>                                    
                </template>   
              </el-table-column>                                       
              
              <el-table-column label="Nome" prop="aluno_nome" width="250" />
              <el-table-column
                label="Escola"
                prop="escola_apelido"
                width="180"
              />
              <el-table-column label="Ensino" prop="ensino" width="100" />
              <el-table-column
                label="Posição na Escola"
                prop="escola_eleicao_posicao"
                width="90"
              />
              <el-table-column label="Dt.Nasc" prop="dtnasc" width="150" />
              <el-table-column
                label="Situação"
                prop="situacao_eleicao"
                width="150"
              />
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { ArrowDown, Document } from "@element-plus/icons-vue";
import { ElLoading } from "element-plus";
import ToastMixin from "@/mixins/toastMixin";
import EscolaEleicaoModel from "@/models/EscolaEleicaoModel";
import EleicaoModel from "@/models/EleicaoModel";
import api from "@/services/api.js";
import moment from "moment";
import { getAccessTokenAsParam } from "@/utils/auth";
//import { writeFile } from 'fs/promises';

export default {
  name: "CandidatoList",
  components: {
    ArrowDown,
    Document,
  },
  mixins: [ToastMixin],
  data() {
    return {
      filtro: {
        eleicao_id: null,
      },
      search: "",
      listaClassificacao: [],
      escolaEleicao: [],
      eleicaoToFilter: [],
      emptyImage: require("@/assets/images/empty-school.svg"),
      candidato_image: require("@/assets/images/candidato.png"),
    };
  },
  async created() {
    await this.getEleicao();
  },
  methods: {
    getImage(item) {
      if (item.aluno.thumbnail_path != null) {
        let token = getAccessTokenAsParam();
        return item.aluno.thumbnail_path + token;
      } else return this.candidato_image;
    },  
    getImageClassificacao(item) {      
      if (item.aluno_thumbnail_path != null) {
        if (!item.aluno_thumbnail_path.includes('?token')){
          let token = getAccessTokenAsParam();
          item.aluno_thumbnail_path=item.aluno_thumbnail_path + token}        
        return item.aluno_thumbnail_path
      } else return this.candidato_image;
    },          
    formatterDate(row) {
      return moment(String(row.dtnasc)).format("MM/DD/YYYY");
    },
    async exportarWord(opcao) {          
      
      if (opcao == "docx") {
        api({
          url: "escolaeleicao/classificacaoWordExport",
          data: this.listaClassificacao,
          method: "POST",
          responseType: "blob",
        }).then((response) => {
          const docxContents = response.data;
          let a = document.createElement("a");
          let blobURL = URL.createObjectURL(docxContents);
          a.download = "Lista de Classificação.docx";
          a.href = blobURL;
          a.click();
          //window.open(URL.createObjectURL(response.data));
        });
      } else {
        let loadingInstance = ElLoading.service({
          text: "Aguarde",
          lock: true,
        });     
           
        api({
          url: "escolaeleicao/classificacaoPDFExport",
          data: this.listaClassificacao,
          method: "POST",
          /*responseType: "text/html"*/
          responseType: "blob",
        }).then((response) => {
          loadingInstance.close();
          window.open(URL.createObjectURL(response.data));          

          //let responseHtml = response.data;
          //console.log(responseHtml, "Monitoring");
        //open the new window and write your HTML to it
          /*var myWindow = window.open("", "response", "resizable=yes");
          myWindow.document.write(responseHtml);*/
                    
          /*const docxContents = response.data;
          let a = document.createElement("a");
          let blobURL = URL.createObjectURL(docxContents);
          a.download = "Lista de Classificação.pdf";
          a.href = blobURL;
          a.click();*/
          
          //window.open(URL.createObjectURL(response.data));
        });
      }

      // await EscolaEleicaoModel.custom('escolaeleicao/classificacaoWordExport').params(this.listaClassificacao).save();
    },
    tableRowClassName(row) {
      if (row.row.situacao_eleicao === "Eleito") {
        return "success-row";
      } else if (row.row.situacao_eleicao === "Suplente") {
        return "warning-row";
      }
      return "";
    },
    getResultado() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

        EscolaEleicaoModel.custom("escolaeleicao/apuracao")
          .params({ eleicao_id: this.filtro.eleicao_id })
          .get()
          .then( (response) =>{          
            this.escolaEleicao = response.data;

            
            EscolaEleicaoModel.custom("escolaeleicao/classificacao")
              .params({ eleicao_id: this.filtro.eleicao_id })
              .get()
              .then( (response) => {
                this.listaClassificacao = response;
              })
              .catch(error =>{
                console.log(error);
                this.showToast("error", "", "Erro: " + error.response.data.message);
              })          
          })
        .catch( (error) =>{
          console.log(error);
          this.showToast("error", "", "Problema ao excluir registro: " + error);
        });
      loadingInstance.close();
    },
    async getEleicao() {
      let response = await EleicaoModel.params({
        ativo: 1,
      }).get();
      this.eleicaoToFilter = response.data;
      this.filtro.eleicao_id =
        this.eleicaoToFilter[this.eleicaoToFilter.length - 1].id;
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  computed: {
    isEscolaEleicaoEmpty() {
      return this.escolaEleicao.length == 0;
    },
  },
};
</script>

<style>
.classificacao-avatar{
  display: flex;
  align-items: center;
}

.classificacao-avatar > b{
  margin-right: 5px;
  width: 20px;
}


.candidato-conteudo{
  display: block;
  width: 100%;  
}

.candidato-avatar{
  margin-right: 3px;
}

.candidato{
  display: flex;
  flex-direction: row;
}

.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-data-image {
  width: 300px;
  height: 300px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .warning-row td:last-child {
  color: darkslateblue;
  font-weight: bold;
}

.el-table .success-row td:last-child {
  color: green;
  font-weight: bold;
}
</style>
