import axios from "axios";
import {refreshToken} from "@/utils/auth";

const api = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
	//baseURL: "http://eleicaobackend.test/api"
	//headers: {
	//    Authorization : `Bearer ${localStorage.getItem("token")}`
	//}    
})

api.interceptors.request.use(
	config => {
		let credenciais = JSON.parse(localStorage.getItem('credenciais'));
		if (credenciais)
			config.headers['Authorization'] = `Bearer ${credenciais.access_token}`;
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);


api.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalConfig = error.config;
		
		let credenciais = JSON.parse(localStorage.getItem('credenciais'));
		console.log(error);
		let errorCode = (error.response == undefined) ? 400 : error.response.status;


		if (errorCode === 401 && !originalConfig._retry && credenciais ) {	
			originalConfig._retry = true;

			try {
				await refreshToken(error);
				//const { accessToken } = rs.data;				
				//TokenService.updateLocalAccessToken(accessToken);
				return api(originalConfig);
			}catch(_error) {
				return Promise.reject(_error);
			}

			//const response = await refreshToken(error);
			//return response;
		}
		return Promise.reject(error);
	}
);

export default api;