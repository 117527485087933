<template>
    <footer class="no-print">      
        <el-icon color="white"><Monitor /></el-icon>  
        <span class="footer-text">Desenvolvido pelo setor de TI da Câmara Municipal de Ibitinga    -    v1.00</span>
        <a class="social-link" v-for="(socialLink, index) in socialLinks" :key="index" :href="socialLink.link" target="blank">
            <img :alt="socialLink.label" :title="socialLink.label" :src="socialLink.icon">
        </a>
    </footer>
</template>

<script>
    //import api from '@/services/api.js';
    import { Monitor } from "@element-plus/icons-vue";

    export default {
        name: 'Footer',
        components: {Monitor},
        data(){
            return{
                socialLinks: []
            }
        },
        mounted(){
            //api.get('/social-links.json').then(response =>{
            //    this.socialLinks = response.data;
            //})
        }
    }
</script>


<style scoped>

  @media print{
    .no-print {
      display: none;
    } 
  }


  .footer-text{
    color: white;
    font-size: 11px;
    margin-left: 2px;
  }


    footer{
        background-color: var(--color-background-nav);
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .social-link{   
        border: 1px solid var(--color-text-light);
        width: 40px;    
        height: 40px;
        border-radius: 50%;                     
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
    .social-link img{
        width: 20px;
        height: 20px; 
             
    }   




</style>