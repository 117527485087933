import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/pages/Login';
import Home from '@/pages/Home';
import Videos from '@/pages/Videos';
import Sobre from '@/pages/Sobre';
import Contatos from '@/pages/Contatos';
import Aluno from '@/pages/Aluno/Aluno';
import AlunoList from '@/pages/Aluno/AlunoList';
import Usuario from '@/pages/Usuario/Usuario';
import UsuarioList from '@/pages/Usuario/UsuarioList';
import Escola from '@/pages/Escola/Escola';
import EscolaList from '@/pages/Escola/EscolaList';
import Eleicao from '@/pages/Eleicao/Eleicao';
import EleicaoList from '@/pages/Eleicao/EleicaoList';
import EscolaEleicaoList from '@/pages/EscolaEleicao/EscolaEleicaoList';
import Candidato from '@/pages/Candidato/Candidato';
import CandidatoList from '@/pages/Candidato/CandidatoList';
import VotacaoList from '@/pages/Votacao/VotacaoList';
import Votacao from '@/pages/Votacao/Votacao';
import ApuracaoList from '@/pages/Apuracao/ApuracaoList';
import BoletimUrna from '@/pages/Apuracao/BoletimUrna';
import Zeresima from '@/pages/Apuracao/Zeresima';
import Parametro from '@/pages/Parametro/Parametro';
import { isSignedIn, hasRole } from '../utils/auth';
import PageNotFound from '@/components/PageNotFound';

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {requiresAuth: true, requireAdmin: true}
    },
    {
        name: 'videos',
        path: '/videos',
        component: Videos
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        name: 'sobre',
        path: '/sobre',
        component: Sobre
    },
    {
        name: 'contatos',
        path: '/contatos',
        component: Contatos
    },
    {
        name: 'aluno-list',
        path: '/aluno',
        component: AlunoList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'aluno-create',
        path: '/aluno/create',
        component: Aluno,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'aluno-edit',
        path: '/aluno/:id/edit',
        component: Aluno,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'usuario-list',
        path: '/usuario',
        component: UsuarioList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'usuario-create',
        path: '/usuario/create',
        component: Usuario,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'usuario-edit',
        path: '/usuario/:id/edit',
        component: Usuario,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'escola-list',
        path: '/escola',
        component: EscolaList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'escola-create',
        path: '/escola/create',
        component: Escola,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'escola-edit',
        path: '/escola/:id/edit',
        component: Escola,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'eleicao-list',
        path: '/eleicao',
        component: EleicaoList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'eleicao-create',
        path: '/eleicao/create',
        component: Eleicao,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'eleicao-edit',
        path: '/eleicao/:id/edit',
        component: Eleicao,
        meta: { requiresAuth: true},
    },
    {
        name: 'escola-eleicao-list',
        path: '/escola-eleicao',
        component: EscolaEleicaoList,
        meta: { requiresAuth: true, requireAdmin: true},
    },    
    {
        name: 'candidato-list',
        path: '/candidato',
        component: CandidatoList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'candidato-create',
        path: '/candidato/create',
        component: Candidato,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'candidato-edit',
        path: '/candidato/:id/edit',
        component: Candidato,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'votacao-list',
        path: '/votacao',
        component: VotacaoList,
        meta: { requiresAuth: true},
    },
    {
        name: 'votacao-inicio',
        path: '/votacao/inicio',
        component: Votacao,
        meta: { requiresAuth: true},
    },
    {
        name: 'apuracao-list',
        path: '/apuracao',
        component: ApuracaoList,
        meta: { requiresAuth: true, requireAdmin: true},
    },
    {
        name: 'boletim-urna',
        path: '/boletim-urna',
        component: BoletimUrna,
        meta: { requiresAuth: true, requireAdmin: true},
    }, 
    {
        name: 'zeresima',
        path: '/zeresima',
        component: Zeresima,
        meta: { requiresAuth: true, requireAdmin: true},
    },   
    {
        name: 'parametro',
        path: '/parametro',
        component: Parametro,
        meta: { requiresAuth: true, requireAdmin: true},
    },    
    {
        name: '404',
        path: '/404',
        component: PageNotFound,
        meta: { requiresAuth: true, requireAdmin: true},        
    },    
    {        
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }

];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {    
    const isAdmin = hasRole(1);
    if (to.meta.requiresAuth) {
        isSignedIn()
            .then(response=>{
                let isSigned = response[0] ? response[0] : response;
                
                if (!isSigned){                      
                    next('/login');            
                }else if (to.meta.requireAdmin && !isAdmin){
                    next('/votacao');
                }else 
                    next();                
            })
            .catch(()=>{
                next('/login'); 
            });
        

       /*-Promise.all(
            [isSignedIn()]
        ).then(teste=>{
            console.log("resolveu a promessa");
            console.log(teste);

            if (!teste){  
                console.log("despachou");
                next('/login');            
            }else if (to.meta.requireAdmin && !isAdmin){
                next('/votacao');
            }else 
                next();
        }).catch(()=>{
            next('/login');   
        })*/

        /*let isSigned = isSignedIn();    
        if (isSigned){  
            console.log("despachou");
            next('/login');            
        }else if (to.meta.requireAdmin && !isAdmin){
            next('/votacao');
        }else 
            next(); */      
    } else {  
        next();
    }
})


export default router;

