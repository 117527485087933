<template>
  <el-main style="max-width: 1200px; margin: 0 auto; min-height: calc(100vh - 220px)">    
      <el-row>
        <el-col :span="24"><h1>Lista de Usuários</h1></el-col>
      </el-row>
    
    <el-button size="small" type="primary" @click="novoUsuario()"
      >Novo Usuário</el-button
    >
    <el-table :data="filterTableData" style="width: 100%">
      <el-table-column type="index" label="#" prop="id" />
      <el-table-column label="Nome" prop="nome" />
      <el-table-column label="Login" prop="login" />
      <el-table-column label="Ativo" prop="ativo" :formatter="formatter" />
      <el-table-column align="right">
        <template #header>
          <el-input
            v-model="search"
            size="small"
            placeholder="Digite para buscar"
          />
        </template>
        <template #default="scope">
          <el-button size="small" @click="editar(scope.$index, scope.row)"
            >Editar</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="openModalExclusao(scope.row)"
            >Excluir</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="modalExclusaoVisible" title="Exclusão">
      <span
        >Deseja realmente excluir este usuário?
        {{ usuarioSelecionado.nome }}</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalExclusaoVisible = false">Cancelar</el-button>
          <el-button type="primary" @click="excluir()">Confirmar</el-button>
        </span>
      </template>
    </el-dialog>
  </el-main>
</template>

<script>
import ToastMixin from "@/mixins/toastMixin";
import StatusCad from "@/utils/statusCad";
import UsuarioModel from "@/models/UsuarioModel";
import { ElLoading } from "element-plus";

export default {
  name: "UsuarioList",
  mixins: [ToastMixin],
  components: [ElLoading],
  data: () => ({
    search: "",
    usuarios: [],
    loading: false,
    usuarioSelecionado: [],
    modalExclusaoVisible: false,
    ativo: StatusCad,
    filter: {
      nome: null,
      ativo: -1,
    },
    optionsList: [
      { value: -1, text: "Selecione algum status" },
      { value: StatusCad.ATIVO, text: "Sim" },
      { value: StatusCad.INATIVO, text: "Não" },
    ],
  }),
  computed: {
    filterTableData: {
      get() {
        var self = this;
        return self.usuarios.filter(
          (data) =>
            !self.search ||
            data.nome.toLowerCase().includes(self.search.toLowerCase())
        );
      },
    },
  },
  async created() {
    this.loading = true;
    await this.pesquisarUsuarios();    
    this.loading = false;    
  },
  methods: {
    async pesquisarUsuarios() {
      let loadingInstance = ElLoading.service({
        text: "Aguarde",
        lock: true,
      });

      await UsuarioModel.get()
        .then((data) => {
          this.usuarios = data.data;
          loadingInstance.close();
        })
        .catch((error) => {
          this.showToast("error", "", error.response.data.message);
          this.$router.go(-1);
          loadingInstance.close();
        });      
    },
    novoUsuario() {
      this.$router.push({ name: "usuario-create" });
    },
    formatter(row) {
      //return this.optionsList[row.ativo+1].text ;
      return row.ativo ? "Sim" : "Não";
    },
    editar(index, row) {
      this.$router.push({ name: "usuario-edit", params: { id: row.id } });
    },
    async openModalExclusao(row) {
      this.usuarioSelecionado = await UsuarioModel.find(row.id);
      this.modalExclusaoVisible = true;
    },
    async excluir() {
      await this.usuarioSelecionado.delete()
      .then( () =>{
        this.showToast("success", "", "Usuário removido com sucesso");
        this.pesquisarUsuarios();                
      })
      .catch(error => {
        this.showToast("error", "", error.response.data.message);})      
      .finally(()=>{
        this.modalExclusaoVisible = false;         
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    async limparFiltros() {
      this.filter = {
        nome: null,
        ativo: null,
      };
      this.usuarios = await UsuarioModel.params({
        ativo: [this.ativo.ATIVO],
      }).get();
    },
  },
};
</script>
