<template>
  <el-main>
    <div class="box">
      <div class="box-header">
        <div class="box-header-logo">
          <img style="width: 90px; height: 90px" :src="logoImage" />
        </div>
        <div class="box-header-text">
          <b>Câmara Municipal de Ibitinga</b>
          <span class="app-name">Sistema de Apoio ao Parlamento Jovem</span>
        </div>
      </div>

      <el-form
        ref="formRef"
        :model="formModel"
        :rules="rules"
        label-width="100px"
        style="max-width: 460px"
      >
        <el-row>
          <el-col :span="24">
            <h1>Login</h1>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Usuário" prop="login">
              <el-input v-model="formModel.login"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Senha" prop="password">
              <el-input v-on:keyup.enter="onSubmit('formRef')" type="password" v-model="formModel.password"></el-input>
            </el-form-item>
          </el-col>

          <!--
          <el-col :span="24">
            <el-form-item label="teste">
            <el-select :disabled="teste" v-model="selectValue" placeholder="Select">
              <el-option :key="1" :label="'Opção 1'" :value="1"/>
              <el-option :key="2" :label="'Opção 2'" :value="2"/>
              <el-option :key="3" :label="'Opção 3'" :value="3"/>
            </el-select>              
            </el-form-item>
          </el-col>     -->     

          <el-col :span="24">
            <el-form-item style="margin-bottom: 0px;">
              <el-button style="margin-left: auto;" type="primary" @click="onSubmit('formRef')">Logar</el-button>
              <!--<el-button style="margin-left: auto;" type="primary" @click="teste=!teste">Desabilitar</el-button
              >-->              
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-main>
</template>

<script>

import { signIn } from '../utils/auth';
import ToastMixin from "@/mixins/toastMixin";
import { ElLoading } from "element-plus";

export default {
  name: "Login",
  mixins: [ToastMixin], 
  components: [ElLoading],
  data() {
    return {
      teste: false,
      logoImage: require("@/assets/images/logo_camara_mirim_peq.png"),
      selectValue: 1,
      formModel: {
        login: "",
        password: "",
      },
      rules: {
        login: [
          {
            required: true,
            message: 'Preencha o Usuário',
            trigger: "blur",            
          },
        ],
        password: [
          {
            required: true,
            validador: this.validatePass,
            message: 'Preencha a Senha',
            trigger: "blur",      
          },
        ],
      },
    };
  },
  validations: {
    formLogin: {
      usuario: { required: true },
    },
  },
  methods: {
    getCssVarName() {
      return "--el-box-shadow";
    },
    onSubmit(formName) {         
      if (!this.$refs[formName]) return;
      
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let loadingInstance = ElLoading.service({
            text: "Aguarde",
            lock: true,
          });

          await signIn(this.formModel.login, this.formModel.password)
          .then(()=>{            
            this.$router.push({ name: "home" });          
          })
          .catch((error) =>{ 
            let errorMessage = (error.response==undefined) ? error : error.response.data.message;
            this.showToast('error','', errorMessage); 
          })
          .finally (()=>{
            loadingInstance.close();
          });            
      }});
    },    
    validatePass(rule, value, callback) {      
      if (value === "") {
        callback(new Error("Please input the password"));
      }
    },
  },
};
</script>

<style scoped>

.el-main {  
  display: flex;
  flex-direction: row;
  justify-content: center;  
  height: 100%;    
  align-items: center;
  /*background-color: rgb(235, 232, 232);  */
}

.box {
  border-radius: 10px;
  background-color: white;
  padding: 30px;
  max-width: 600px;
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
}

@media (max-width: 600px) {
  .box {
    padding: 10px;
  }
}

.box-header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  height: 95px;
}

.box-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center; /* */
  width: 100%;
  font-size: 18px;
  color: var(--color-text-dark);
  align-items: center; /* vertical*/
  text-align: center;
}

.box-header-text > .app-name{
  font-size: 16px;
}

h1 {
  font-size: 16px;
  display: flex;
  justify-content: center;
}
</style>