function formatarTelefoneFixo(texto){
    //return texto.replace(/^(\d{2})(\d{4})(\d{4})$/g, '($1) $2-$3') ;
    if (texto!==null){
        texto=texto.replace(/\D/g,""); //Remove tudo o que não é dígito
        texto=texto.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        texto=texto.replace(/(\d)(\d{4})$/,"$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos    
        return texto;
    }else{
        return '';
    }
}


export default{
    formatarTelefoneFixo
}


/*
export default {
    methods : {
        formatarTelefoneFixo(texto){
            return texto.replace(/^(\d{2})(\d{4})(\d{4})$/g, '($1) $2-$3') ;
        }
    },
  }
*/